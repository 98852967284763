import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getInputHeightInPx } from '../../../core/commons/inputUtils';
import {
  AddressInputDefinition,
  AddressInputMapperProps,
  AddressInputCSSVars,
  AddressInputAlignment,
  AddressInputCssCarmiData,
  IAddressInputRefState,
} from '../AddressInput.types';

export const props = withCompInfo<
  AddressInputMapperProps,
  AddressInputDefinition
>()(
  ['compData', 'compProps', 'language', 'externalBaseUrl'],
  ({ compData, compProps, language, externalBaseUrl }) => {
    return {
      placeholder: compData.placeholder,
      readOnly: compProps.readOnly,
      required: compProps.required,
      isDisabled: compProps.isDisabled,
      iconVisible: compProps.iconVisible,
      dividerVisible: compProps.dividerVisible,
      country: compData.filter?.country,
      alignment: compProps.alignment,
      lang: language,
      label: compData.label,
      provider: 'atlas',
      externalBaseUrl,
    };
  },
);

const alignmentCssVars = (
  alignment: AddressInputAlignment,
  labelPadding: number,
) => {
  return {
    '--textAlign': alignment,
    ...(alignment === 'right'
      ? {
          '--dir': 'rtl' as 'rtl',
        }
      : {
          '--dir': 'ltr' as 'ltr',
        }),
    ...(alignment === 'right'
      ? {
          '--labelPadding': `0 ${labelPadding}px 0 0`,
        }
      : alignment === 'center'
      ? {
          '--labelPadding': '0',
        }
      : {
          '--labelPadding': `0 0 0 ${labelPadding}px`,
        }),
  };
};

export const css = withCompInfo<
  AddressInputCSSVars,
  AddressInputDefinition,
  AddressInputCssCarmiData
>()(
  [
    'styleProperties',
    'compProps',
    'compLayout',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  (
    {
      styleProperties,
      compProps,
      compLayout,
      isMobileView,
      hasResponsiveLayout,
    },
    cssVars,
  ) => {
    const { margin, labelMargin, labelPadding, alignment } = compProps;

    const dropdownOptionPadding =
      typeof margin === 'undefined'
        ? styleProperties.dropdownOptionPadding
        : margin;

    return {
      ...cssVars,
      '--dropdownOptionPadding': `${dropdownOptionPadding}px`,
      ...alignmentCssVars(alignment, labelPadding),
      '--labelMarginBottom': `${labelMargin}px`,
      '--inputHeight': hasResponsiveLayout
        ? undefined
        : getInputHeightInPx({
            compProps,
            compLayout,
            isMobileView,
          }),
      '--inputFlex': hasResponsiveLayout ? 1 : undefined,
      '--inputContainerHeight': hasResponsiveLayout ? '100%' : undefined,
    };
  },
);

export const stateRefs = withStateRefsValues<IAddressInputRefState>([
  'domain',
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
