import React, { createRef } from 'react';
import type { LinkProps } from '@wix/editor-elements-types/thunderbolt';
import { IPayPalButtonProps } from '../PayPalButton.types';
import { TestIds } from '../constants';
import { toQueryString } from '../../../core/commons/urlUtils';
import { activateBySpaceOrEnterButton } from '../../../core/commons/a11y';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/PayPalButton.scss';

type PayPalButtonState = {
  notifyUrl: string;
  formTarget: LinkProps['target'];
};

export const getNotifyUrl = ({
  metaSiteId,
  userId,
  getVisitorId,
}: {
  metaSiteId: string;
  userId: string;
  getVisitorId: () => string;
}): string =>
  `https://www.wix.com/_serverless/paypal-callback?${toQueryString({
    metaSiteId,
    userId,
    visitorId: getVisitorId(),
    renderer: 'thunderbolt',
  })}`;

class PayPalButton extends React.Component<
  IPayPalButtonProps,
  PayPalButtonState
> {
  form: React.RefObject<HTMLFormElement> = createRef();
  state = {
    notifyUrl: '',
    formTarget: this.props.formTarget,
  };

  formSubmitHandler = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    const notifyUrl = getNotifyUrl(this.props);
    this.setState({ notifyUrl }, () => {
      this.form.current!.submit();
    });
  };

  componentDidMount() {
    // Temporarly workaround -
    // Click redirects to PayPal homepage in Facebook in-app browser in iOS
    // https://stackoverflow.com/questions/32488946/website-paypal-button-from-facebook
    const isFacebookInAppBrowser =
      !!navigator.userAgent.match(/FBAV|FBAN|Instagram/i);
    const isIOS = !!navigator.userAgent.match(/iPad|iPod|iPhone/i);
    if (isFacebookInAppBrowser && isIOS) {
      this.setState({
        formTarget: '_self',
      });
    }
  }

  render() {
    const {
      id,
      className,
      cmdType,
      itemName,
      itemNumber,
      buildNotation,
      business,
      currencyCode,
      amount,
      submitImageSrc,
      submitImageAriaLabel,
      trackingPixelImgSrc,
      currentUrl,
      onMouseEnter,
      onMouseLeave,
    } = this.props;

    return (
      <div
        id={id}
        {...getDataAttributes(this.props)}
        className={className}
        style={{ height: 'auto' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <form
          target={this.state.formTarget}
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          ref={this.form}
        >
          <input type="hidden" name="cmd" value={cmdType} />
          <input type="hidden" name="item_name" value={itemName || undefined} />
          <input
            type="hidden"
            name="item_number"
            value={itemNumber || undefined}
          />
          <input type="hidden" name="bn" value={buildNotation} />
          <input type="hidden" name="business" value={business} />
          <input type="hidden" name="currency_code" value={currencyCode} />
          <input type="hidden" name="notify_url" value={this.state.notifyUrl} />
          <input type="hidden" name="return" value={currentUrl} />
          <input type="hidden" name="cancel_return" value={currentUrl} />
          <input type="hidden" name="amount" value={amount || undefined} />

          <img
            alt=" "
            className={style.submitImage}
            role="button"
            src={submitImageSrc}
            tabIndex={0}
            aria-label={submitImageAriaLabel}
            data-testid={TestIds.submitImg}
            onClick={this.formSubmitHandler}
            onKeyDown={activateBySpaceOrEnterButton}
          />
          <img
            alt=" "
            className={style.trackingPixel}
            width="1"
            height="1"
            role="none"
            src={trackingPixelImgSrc}
            data-testid={TestIds.trackingPixel}
          />
        </form>
      </div>
    );
  }
}

export default PayPalButton;
