import React from 'react';
import ReactDOM from 'react-dom';
import { WixProGallery as BaseGallery } from '@wix/pro-gallery-wix-wrapper';

// TODO - migrate to a shared place instead of using deep import
import { IFullscreenGalleryProps } from '../../ProGallery.types';
import CloseButton from '../assets/CloseButton';
import { st, classes } from '../style/ProGallery.component.st.css';
import getExperimentalFeatures from '../../common/getExperimentalFeatures';

const GalleryFullScreen: React.FC<IFullscreenGalleryProps> = props => {
  const { className, items, activeIndex, id, deviceType, viewMode, onClose } =
    props;

  const isMobile = deviceType === 'mobile';
  const isExperimentOpen = () => true; // TODO: Pass the real stuff later
  const experimentalFeatures = getExperimentalFeatures(isExperimentOpen);

  return activeIndex >= 0
    ? ReactDOM.createPortal(
        <div className={st(classes.fullscreen, className)}>
          <CloseButton
            className={st(classes.x, className)}
            onClick={onClose}
            size={25}
          />
          <BaseGallery
            experimentalFeatures={experimentalFeatures}
            id={id}
            items={items}
            activeIndex={activeIndex}
            styles={{
              galleryLayout: 5,
              slideshowInfoSize: 80,
              slideAnimation: isMobile ? 'SCROLL' : 'FADE',
              cubeType: 'fit',
              scrollSnap: true,
              showArrows: !isMobile,
            }}
            container={{
              width: window.innerWidth,
              height: window.innerHeight,
            }}
            viewMode={viewMode!}
            deviceType={deviceType!}
          />
        </div>,
        document.body,
      )
    : null;
};

export default GalleryFullScreen;
