import {
  withCompPreviewInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  FacebookShareDefinition,
  FacebookSharePlatformProps,
} from '../FacebookShare.types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import { props as viewerPropsMapper } from './FacebookShare.mapper';

const props = composeMapper<
  PreviewWrapperProps<FacebookSharePlatformProps>,
  FacebookShareDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { isSocialInteractionAllowed } = dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(isSocialInteractionAllowed),
      },
    };
  },
);

const css = withCompPreviewInfo<{ width?: string }, FacebookShareDefinition>()(
  ['isSocialInteractionAllowed'],
  ({ isSocialInteractionAllowed }) =>
    isSocialInteractionAllowed ? {} : { width: 'auto' },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
