import * as React from 'react';
import WrapperElement from '../../WrapperElement';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import skinStyles from './styles/skins.scss';

const InnerShadowScreen: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps,
  children,
}) => {
  return (
    <WrapperElement
      {...wrapperProps}
      skinClassName={skinStyles.InnerShadowScreen}
      skinStyles={skinStyles}
    >
      <div className={skinStyles.screenWidthBackground}></div>
      <div className={skinStyles.centeredContent}>
        <div className={skinStyles.centeredContentBg}>
          <div className={skinStyles.bg}></div>
        </div>
        <div className={skinStyles.inlineContent}>{children}</div>
      </div>
    </WrapperElement>
  );
};

export default InnerShadowScreen;
