
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TabsScrollButton1157534303",
      {"classes":{"root":"TabsScrollButton1157534303__root","icon":"TabsScrollButton1157534303__icon"},"vars":{},"stVars":{"tabsListItemBorderWidth":"var(--tabs-list-item-border-width, 0)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  