import type { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import { FacebookLocale } from './WFacebookLike.types';
import {
  Language,
  Action,
} from './documentManagement/WFacebookLike.schemaTypes';

export const DEFAULT_FACEBOOK_LOCALE = 'en_US';
export const LAYOUT_STANDARD = 'standard';
export const LAYOUT_BUTTON_COUNT = 'button_count';
export const LAYOUT_BOX_COUNT = 'box_count';
export const ACTION_LIKE = 'like';
export const ACTION_RECOMMEND = 'recommend';

export const languageToFacebookLocale: Partial<
  {
    [P in Exclude<Language, 'userLang'> | CompInfo['language']]: FacebookLocale;
  }
> = {
  da: 'da_DK',
  de: 'de_DE',
  en: 'en_US',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
  ja: 'ja_JP',
  kr: 'ko_KR',
  nl: 'nl_NL',
  no: 'nn_NO',
  pl: 'pl_PL',
  pt: 'pt_BR',
  ru: 'ru_RU',
  sv: 'sv_SE',
  tr: 'tr_TR',
};

// standard layout
export const widthForStandardLayoutByAction: Record<Action, number> = {
  [ACTION_LIKE]: 250,
  [ACTION_RECOMMEND]: 290,
};

export const heightForStandardLayoutByAction: Record<Action, number> = {
  [ACTION_LIKE]: 40,
  [ACTION_RECOMMEND]: 40,
};

export const widthForStandardLayoutByActionWithFaces: Record<Action, number> = {
  [ACTION_LIKE]: 250,
  [ACTION_RECOMMEND]: 290,
};

export const heightForStandardLayoutByActionWithFaces: Record<Action, number> =
  {
    [ACTION_LIKE]: 85,
    [ACTION_RECOMMEND]: 85,
  };

// button_count layout
export const widthForButtonCountLayoutByActionAndLocale: Record<
  Action,
  Record<FacebookLocale, number>
> = {
  [ACTION_LIKE]: {
    en_US: 77,
    es_ES: 105,
    ru_RU: 125,
    pt_BR: 95,
    ko_KR: 98,
    de_DE: 114,
    da_DK: 159,
    fr_FR: 92,
    it_IT: 114,
    ja_JP: 104,
    nl_NL: 150,
    nn_NO: 123,
    pl_PL: 114,
    sv_SE: 75,
    tr_TR: 100,
  },
  [ACTION_RECOMMEND]: {
    en_US: 121,
    es_ES: 124,
    ru_RU: 153,
    pt_BR: 132,
    ko_KR: 89,
    de_DE: 116,
    da_DK: 121,
    fr_FR: 135,
    it_IT: 121,
    ja_JP: 104,
    nl_NL: 151,
    nn_NO: 101,
    pl_PL: 97,
    sv_SE: 133,
    tr_TR: 120,
  },
};

export const heightForButtonCountLayoutByAction: Record<Action, number> = {
  [ACTION_LIKE]: 20,
  [ACTION_RECOMMEND]: 20,
};

export const widthForButtonCountLayoutByActionWithFaces: Record<
  Action,
  number
> = {
  [ACTION_LIKE]: 137,
  [ACTION_RECOMMEND]: 137,
};

export const heightForButtonCountLayoutByActionWithFaces: Record<
  Action,
  number
> = {
  [ACTION_LIKE]: 20,
  [ACTION_RECOMMEND]: 20,
};

// box_count layout
export const widthForBoxCountLayoutByActionAndLocale: Record<
  Action,
  Record<FacebookLocale, number>
> = {
  [ACTION_LIKE]: {
    en_US: 51,
    es_ES: 76,
    ru_RU: 81,
    pt_BR: 58,
    ko_KR: 57,
    de_DE: 83,
    da_DK: 107,
    fr_FR: 63,
    it_IT: 72,
    ja_JP: 72,
    nl_NL: 89,
    nn_NO: 74,
    pl_PL: 75,
    sv_SE: 52,
    tr_TR: 62,
  },
  [ACTION_RECOMMEND]: {
    en_US: 95,
    es_ES: 95,
    ru_RU: 109,
    pt_BR: 95,
    ko_KR: 48,
    de_DE: 85,
    da_DK: 69,
    fr_FR: 105,
    it_IT: 78,
    ja_JP: 72,
    nl_NL: 90,
    nn_NO: 52,
    pl_PL: 58,
    sv_SE: 111,
    tr_TR: 82,
  },
};

export const heightForBoxCountLayoutByAction: Record<Action, number> = {
  [ACTION_LIKE]: 40,
  [ACTION_RECOMMEND]: 40,
};

export const widthForBoxCountLayoutByActionWithFaces: Record<Action, number> = {
  [ACTION_LIKE]: 85,
  [ACTION_RECOMMEND]: 125,
};

export const heightForBoxCountLayoutByActionWithFaces: Record<Action, number> =
  {
    [ACTION_LIKE]: 65,
    [ACTION_RECOMMEND]: 65,
  };
