import { withComponentController } from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
  FlickrBadgeControllerProps,
} from '../FlickrBadgeWidget.types';

const compController = withComponentController<
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
  FlickrBadgeControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrl,
  };
});

export default compController;
