import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  RatingsInputPlatformProps,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData,
} from '../RatingsInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './RatingsInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<RatingsInputPlatformProps>,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compProps: { shapeSize, shapeSpacing, direction, labelMargin },
    } = dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
        shapeSize,
        shapeSpacing,
        rtl: direction === 'rtl',
        labelMargin,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: viewerCssMapper,
});
