import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  AccordionContainerDefinition,
  AccordionContainerMapperProps,
  RawItem,
} from '../types';
import { DefaultOpened } from '../constants';

const calcOpened = (items: Array<RawItem>, defaultOpened: DefaultOpened) => {
  const ids = items.map(({ id }) => id);
  switch (defaultOpened) {
    case DefaultOpened.OPEN_ALL:
      return ids;
    case DefaultOpened.OPEN_FIRST:
      return [ids[0]];
    case DefaultOpened.CLOSE_ALL:
    default:
      return [];
  }
};

export const props = withCompInfo<
  AccordionContainerMapperProps,
  AccordionContainerDefinition
>()(
  ['compData', 'compProps', 'compStylableClass'],
  ({ compData, compProps, compStylableClass }) => ({
    ...compData,
    ...compProps,
    opened: calcOpened(compData.items, compProps.defaultOpened),
    className: compStylableClass,
  }),
);

export const css = withCompInfo<any, AccordionContainerDefinition>()(
  ['compProps'],
  ({ compProps: { animated, animationTime } }) => ({
    height: 'auto',
    '--animation-time': animationTime,
    '--icon-animation-time': animated ? animationTime : 0.15,
  }),
);

export default createComponentMapperModel({ props, css });
