import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  CustomElementComponentDefinition,
  CustomElementComponentProps,
} from '../CustomElementComponent.types';
import { props as viewerPropsMapper } from './CustomElementComponent.mapper';

const props = composeMapper<
  PreviewWrapperProps<CustomElementComponentProps>,
  CustomElementComponentDefinition
>()(
  viewerPropsMapper,
  { add: ['componentViewMode', 'compLayout', 'isWixBlocks', 'compData'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { componentViewMode, compLayout, isWixBlocks, compData } = deps;
    const { width, height } = compLayout;
    const { scriptType } = compData;

    return {
      ...viewerProps,
      scriptType,
      previewWrapperProps: {
        componentViewMode,
        isWixBlocks,
        containerSizes: { width, height },
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
