import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { IGridControllerActions } from '../Grid.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IGridControllerActions => ({
  onChangePage: page => {
    updateProps({ currentPage: page });
  },
  onChangeSelectedCell: selectedCell => {
    updateProps({ selectedCell, selectedRow: undefined });
  },
  onChangeSelectedRow: selectedRow => {
    updateProps({ selectedRow, selectedCell: undefined });
  },
});

export default mapActionsToProps;
