import { createBIEventFactory } from '@wix/editor-elements-preview-utils/bi';
import { BiEventFieldType } from '@wix/editor-elements-types/editor';

enum EditorSrc {
  X = 110,
  CLASSIC = 38,
}

interface BiEvent {
  classicEvid?: number;
  editorXEvid?: number;
  params?: Record<string, BiEventFieldType>;
}

const createBIWrapper = (isEditorX: boolean) => {
  const createClassicBIEvent = createBIEventFactory(EditorSrc.CLASSIC);
  const createXBIEvent = createBIEventFactory(EditorSrc.X);

  return (event: BiEvent) => {
    if (typeof event.editorXEvid !== 'undefined' && isEditorX) {
      return createXBIEvent(event.editorXEvid, event.params);
    }

    if (typeof event.classicEvid !== 'undefined' && !isEditorX) {
      return createClassicBIEvent(event.classicEvid, event.params);
    }

    logNoHostWarning(event, isEditorX);

    return {};
  };
};

const logNoHostWarning = (event: BiEvent, isEditorX: boolean) => {
  const host = isEditorX ? 'EDITOR_X' : 'CLASSIC EDITOR';
  const eventEvid = isEditorX ? event.editorXEvid : event.classicEvid;
  // eslint-disable-next-line no-console
  console.warn(`No host found for bi event: ${eventEvid} in ${host}`);
};

export default createBIWrapper;
