import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import type { PreviewTooltipProps } from '@wix/thunderbolt-becky-types';
import { useResetComponent } from '@wix/editor-elements-preview-utils';
import type {
  LanguageSelectorViewerProps,
  LanguageSelectorWithPreviewProps,
} from '../LanguageSelector.types';

function withComponentPreview(
  WrappedComponent: React.ComponentType<LanguageSelectorWithPreviewProps>,
): IComponentPreviewWrapper<LanguageSelectorViewerProps & PreviewTooltipProps> {
  return ({ previewWrapperProps = {}, ...viewerProps }) => {
    const { shouldResetComponent } = previewWrapperProps;

    const key = useResetComponent({
      id: viewerProps.id,
      shouldResetComponent,
    });

    const onMouseEnter = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      if (!viewerProps.disabledReason) {
        return;
      }
      const rect = event.currentTarget.getBoundingClientRect();
      // show the tooltip above the component
      rect.y = rect.top - rect.height;

      viewerProps.showTooltip(
        rect,
        viewerProps.disabledReason === 'current-language'
          ? 'LangMenu_HiddenLanguage_Preview_Notification_Tooltip'
          : 'LangMenu_HiddenMenu_Preview_Notification_Tooltip',
        { width: '300px', alignment: 'bottom' },
      );
    };

    const onChange: LanguageSelectorWithPreviewProps['onChange'] =
      languageCode => {
        viewerProps.setIsOpen(false);
        viewerProps.onChange(languageCode);
      };

    return (
      <WrappedComponent
        {...viewerProps}
        key={key}
        onMouseEnter={onMouseEnter}
        disabled={Boolean(viewerProps.disabledReason)}
        onChange={onChange}
      />
    );
  };
}

export default (
  ViewerComponent: React.ComponentType<LanguageSelectorWithPreviewProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
