import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  StateBoxDefinition,
  StateBoxProps,
  StateBoxCSSVars,
} from '../StateBox.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './StateBox.mapper';

const props = composeMapper<
  PreviewWrapperProps<StateBoxProps>,
  StateBoxDefinition
>()(
  viewerPropsMapper,
  {
    add: ['shouldResetComponent', 'isComponentTransitionAllowed'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    return {
      ...viewerProps,
      transitionDuration: dependencies.isComponentTransitionAllowed
        ? viewerProps.transitionDuration
        : 0,
      previewWrapperProps: {
        shouldResetComponent: dependencies.shouldResetComponent,
      },
    };
  },
);

const css = composeMapper<StateBoxCSSVars, StateBoxDefinition>()(
  viewerCssMapper,
  { add: ['isComponentTransitionAllowed'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const previewCss: Partial<StateBoxCSSVars> = {};
    if (!dependencies.isComponentTransitionAllowed) {
      previewCss['--transition-duration'] = '0';
    }
    return { ...viewerCss, ...previewCss };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
