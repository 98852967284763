import * as React from 'react';
// TODO: Arrange better, deep imports are bad, and skin comps are not exported from index
import Container from '@wix/thunderbolt-elements/dist/components/Container/viewer/skinComps/DefaultAreaSkin/DefaultAreaSkin.skin';
import { getDataAttributes } from '@wix/thunderbolt-elements/src/core/commons/utils';
import { IMegaMenuContainerItemProps } from '../../../MegaMenuContainerItem.types';

const MegaMenuContainerItem: React.FC<IMegaMenuContainerItemProps> = props => {
  const { id, children, meshProps } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <Container
      {...getDataAttributes(props)}
      id={id}
      hasPlatformClickHandler={false}
      meshProps={meshProps}
    >
      {childrenToRender}
    </Container>
  );
};

export default MegaMenuContainerItem;
