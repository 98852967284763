import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import {
  IVerticalAnchorsMenuProps,
  VerticalAnchorsMenuStateRefs,
} from '../VerticalAnchorsMenu.types';

const compController: IComponentController<
  IVerticalAnchorsMenuProps,
  VerticalAnchorsMenuStateRefs
> = {
  mapStateToProps: ({ currentPageHref }) => ({
    currentPageHref,
  }),
};

export default compController;
