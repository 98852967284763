import classNames from 'classnames';
import * as React from 'react';
import { getDataAttributes } from '../../../../../core/commons/utils';
import { VerticalLineProps } from '../VerticalLine';

type BaseButtonSkinProps = VerticalLineProps & {
  skinsStyle: Record<string, string>;
  className?: string;
};

export const BaseVerticalLine: React.FC<BaseButtonSkinProps> = props => {
  const { skinsStyle, id, className, onMouseEnter, onMouseLeave } = props;
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, skinsStyle.root)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {skinsStyle.line && <div className={skinsStyle.line} />}
    </div>
  );
};
