import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { StateRefsValues } from '@wix/thunderbolt-symbols';
import { IMusicPlayerController } from '../MusicPlayer.types';

const mapActionsToProps: IMusicPlayerController = ({ updateProps }) => ({
  onPlay: () => {
    updateProps({ isPlaying: true });
  },
  onPause: () => {
    updateProps({ isPlaying: false });
  },
  onEnded: () => {
    updateProps({ isPlaying: false });
  },
  onTimeUpdated: event => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...payload } = event;
    updateProps({ currentTime: payload.currentTime });
  },
});

const mapStateToProps = ({ reducedMotion }: StateRefsValues) => ({
  reducedMotion,
});

const compController: IComponentController = {
  mapStateToProps,
  mapActionsToProps,
};

export default compController;
