import { withComponentController } from '@wix/editor-elements-integrations';
import {
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
  WTwitterFollowControllerProps,
} from '../WTwitterFollow.types';

const compController = withComponentController<
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
  WTwitterFollowControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrl,
  };
});

export default compController;
