import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../common/utils';
import {
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition,
} from '../WFacebookComment.types';

export const props = withCompInfo<
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition
>()(
  ['compProps', 'isMobileView', 'language'],
  ({ compProps, isMobileView, language }) => ({
    ...compProps,
    width: isMobileView ? 320 : compProps.width,
    language: getLanguage(language),
  }),
);

export const css = withCompInfo<any, WFacebookCommentDefinition>()(
  ['hasResponsiveLayout', 'compLayout'],
  ({ hasResponsiveLayout, compLayout: { height } }) =>
    hasResponsiveLayout
      ? undefined
      : {
          height: 'auto',
          'min-height': `${height}px`,
        },
);

export default createComponentMapperModel({ props, css });
