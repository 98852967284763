import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/GlowLinePhoto.scss';
import BasicWPhotoSkin from './BasicWPhotoSkin';

const GlowLinePhotoSkin: React.FC<Omit<BaseWPhotoSkinProps, 'skinsStyle'>> =
  props => <BasicWPhotoSkin {...props} skinsStyle={skinsStyles} />;

const GlowLinePhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={GlowLinePhotoSkin} />
);

export default GlowLinePhoto;
