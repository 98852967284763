import React from 'react';
import { ComboBoxInputOption } from '@wix/thunderbolt-components';

export function useShowTooltip(
  id: string,
  options: Array<ComboBoxInputOption>,
  skin: string,
  showTooltip?: (rect: DOMRect, text: string) => void,
  componentViewMode?: string,
) {
  React.useEffect(() => {
    const tooltipHandler = (e: Event) => {
      if (showTooltip) {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
          showTooltip(
            element.getBoundingClientRect(),
            'PREVIEW_PAGE_LINK_TOOLTIP',
          );
        }
      }
    };
    if (
      componentViewMode !== 'editor' &&
      skin === 'ComboBoxInputVerticalMenuSkin' &&
      options.length > 0 &&
      showTooltip
    ) {
      const parent = document.getElementById(id);
      if (parent) {
        options.forEach((optionsItem: ComboBoxInputOption) => {
          const element = parent.querySelector(
            `[value='${optionsItem.value}']`,
          );
          if (element) {
            element.addEventListener('click', tooltipHandler);
          }
        });
      }
    }
  }, [id, options, componentViewMode, skin, showTooltip]);
}
