import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterTweetDefinition,
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
} from '../WTwitterTweet.types';

export const props = withCompInfo<
  WTwitterTweetMapperProps,
  WTwitterTweetDefinition
>()(
  ['compData', 'compProps', 'compLayout', 'language', 'isMobileView'],
  ({ compData, compProps, compLayout, language, isMobileView }) => {
    return {
      defaultText: compData.defaultText,
      accountToFollow: compData.accountToFollow,
      isMobileView,
      lang: getLanguage(compProps.dataLang, language),
      width: compLayout.width || 0,
      height: compLayout.height || 0,
    };
  },
);

export const stateRefs = withStateRefsValues<WTwitterTweetStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
