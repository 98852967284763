import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { DatePickerCarmiProps } from '@wix/thunderbolt-components-native';
import {
  DatePickerPlatformProps,
  DatePickerDefinition,
} from '../DatePicker.types';
import { props as viewerPropsMapper } from './DatePicker.mapper';

const props = composeMapper<
  PreviewWrapperProps<DatePickerPlatformProps>,
  DatePickerDefinition,
  DatePickerCarmiProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'shouldResetComponent',
      'componentViewMode',
      'experiments',
      'hasResponsiveLayout',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compProps,
      // experiments,
      // hasResponsiveLayout,
    } = dependencies;
    // const withCalendarPortal =
    //   (experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
    //     experiments['specs.thunderbolt.DatePickerPortal'] === true) &&
    //   !hasResponsiveLayout;

    return {
      ...viewerProps,
      // withCalendarPortal,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
        defaultTextType: compProps.defaultTextType,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
