import * as React from 'react';
import classnames from 'classnames';

import Loader from 'wix-style-react/dist/src/Loader';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';

import SiteMembersInput, {
  ISiteMembersInputRef,
} from '../../SiteMembersInput/viewer/SiteMembersInput';
import {
  figureFallbackErrorMessage,
  serverErrorsHandler,
} from '../../SiteMembersInput/viewer/utils';

import { IVerificationCodeProps } from '../VerificationCodeDialog.types';
import { getDataAttributes } from '../../../core/commons/utils';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import style from './style/style.scss';

import {
  VerificationCodeDialogTranslationKeys as keys,
  testIds,
  codeInput,
  translationFeature,
} from './constants';

const VerificationCodeDialog: React.FC<IVerificationCodeProps> = props => {
  const {
    id,
    className,
    onCloseDialogCallback,
    isCloseable,
    displayMode,
    directionByLanguage,
    email,
    onSubmitCallback,
    onResendVerificationCodeEmail,
    error = '',
  } = props;
  const translate = props.translate!.bind(null, translationFeature);
  const headlineId = `VerificationCodeHeadline_${id}`;
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState(
    figureFallbackErrorMessage(error, translate),
  );

  const title = translate(keys.title, 'Check your email');
  // TODO: get rid of replace once we GA the initial patch and changed the translations
  const emailParagraph = translate(
    keys.emailParagraph,
    `To confirm your email address, please enter the 6-digit code we sent \nto ${email}`,
    // eslint-disable-next-line no-template-curly-in-string
  ).replace('${email}', '');
  const submitButtonLabel = translate(keys.submitButton, 'Confirm Email');
  const footerParagraph = translate(
    keys.footerParagraph,
    'Didn’t get the email? Check your spam.',
  );
  const footerParagraphLink = translate(
    keys.footerParagraphLink,
    'Resend Code',
  );
  const codeRef = React.useRef<ISiteMembersInputRef>(null);
  const submitForm = async () => {
    setLoading(true);
    if (codeRef.current!.validate(translate)) {
      try {
        await onSubmitCallback(code);
      } catch (errorRespond: any | string | number) {
        setLoading(false);
        const errorMsg = serverErrorsHandler(errorRespond);
        const defaultErrorMsg = translate(
          keys.verificationErr,
          'Email verification failed.',
        );
        setCodeError(errorMsg);
        codeRef.current!.setError(translate(errorMsg, defaultErrorMsg));
      }
    }
  };
  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      translate={props.translate}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      displayMode={displayMode}
      dialogPosition="start"
    >
      <div className={classnames(style.memberLoginContent)}>
        <h1 className={classnames(style.title)} data-testid={testIds.headline}>
          {title}
        </h1>

        <div className={style.paragraph}>
          <span>{emailParagraph}</span>
          <span>{email}</span>
        </div>
        <div>
          <div className={style.textFieldWrapper}>
            <SiteMembersInput
              maxLength={6}
              id={codeInput}
              inputType="text"
              data-testid={codeInput}
              value={code}
              label={''}
              onValueChanged={setCode}
              ref={codeRef}
              isValid={true}
              autoFocus={true}
              directionByLanguage={directionByLanguage}
              errorMessage={codeError}
              borderInput
            />
          </div>
          <div className={style.ButtonWrapper}>
            {loading ? (
              <Loader />
            ) : (
              <div data-testid="submit" className={style.actionButton}>
                <BasicButton
                  label={submitButtonLabel}
                  id={`okButton_${id}`}
                  isDisabled={code?.length < 6}
                  hasPlatformClickHandler={true}
                  link={undefined}
                  onClick={submitForm}
                />
              </div>
            )}
          </div>
          <div className={style.paragraph}>
            <span>{footerParagraph}</span>
          </div>
          <div className={style.someInfoTxt}>
            <span
              className={style.resendVerificationCodeEmailButton}
              data-testid={testIds.resendVerificationCodeEmailButton}
              onClick={onResendVerificationCodeEmail}
              role="button"
              tabIndex={0}
            >
              {footerParagraphLink}
            </span>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default VerificationCodeDialog;
