import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { ITextInputProps } from '../../../../../TextInput/TextInput.types';
import TextInput from '../../../../../TextInput/viewer/TextInput';
import { useGesture } from '../../../../../../providers/useGesture/useGesture';
import { ISearchBoxLayoutProps } from '../..';
import { ClearButton } from '../clearButton';
import { useAutocomplete } from './useAutocomplete';

import style from './SearchInput.scss';

export type ISearchInputProps = Pick<
  ISearchBoxLayoutProps,
  | 'autocompleteValue'
  | 'changeValue'
  | 'clearButtonLabel'
  | 'clearValue'
  | 'id'
  | 'inputRef'
  | 'isDisabled'
  | 'onAutocomplete'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onDblClick'
  | 'onFocus'
  | 'onKeyPress'
  | 'placeholder'
  | 'value'
  | 'isModalSuggestionsShown'
> & {
  containerRef: React.RefObject<HTMLElement>;
};

const commonTextInputProps = {
  autoComplete_: 'off',
  autoComplete: false,
  required: false,
  shouldShowValidityIndication: false,
  skin: 'ResponsiveTextInputSkin',
  validateValueAndShowIndication: () => {},
} as const;

export const SearchInput: React.FunctionComponent<ISearchInputProps> = ({
  autocompleteValue,
  changeValue,
  clearButtonLabel,
  clearValue,
  containerRef,
  id,
  inputRef,
  isDisabled,
  onAutocomplete,
  onBlur,
  onChange,
  onClick,
  onDblClick,
  onFocus,
  onKeyPress,
  placeholder,
  value,
  isModalSuggestionsShown,
}) => {
  const shouldDisplayClearButton = Boolean(value);

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        changeValue(newValue);
        onChange?.({ type: 'change', compId: id, previousValue: value });
      }
    },
    [id, changeValue, onChange, value],
  );

  useEffect(() => {
    // manually trigger the onFocus event when input focused before hydration
    const activeElement = document.activeElement;
    if (
      !activeElement ||
      !activeElement.id.includes(id) ||
      isModalSuggestionsShown
    ) {
      return;
    }
    activeElement.dispatchEvent(new FocusEvent('focus'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    autocompleteInputValue,
    onAutocompleteKeyPress,
    onAutocompleteInput,
    onAutocompleteSwipeRight,
  ] = useAutocomplete({
    autocompleteValue,
    id,
    onAutocomplete,
    onChange: handleValueChange,
    value,
  });

  const handleInput: ITextInputProps['onInput'] = event => {
    onAutocompleteInput(event);
  };

  const handleKeyPress: ITextInputProps['onKeyPress'] = event => {
    onAutocompleteKeyPress(event);
    onKeyPress?.(event);
  };

  useGesture('onSwipeRight', onAutocompleteSwipeRight, containerRef);

  return (
    <div
      className={classNames(style.container, {
        [style.withClearButton]: shouldDisplayClearButton,
      })}
    >
      <div className={style.inputContainer}>
        {autocompleteInputValue && (
          <TextInput
            {...commonTextInputProps}
            className={style.autocompleteInput}
            inputType="text"
            id={`search-box-autocomplete-input-${id}`}
            dataTestId="search-box-autocomplete-input"
            isDisabled
            readOnly
            value={autocompleteInputValue}
          />
        )}
        <TextInput
          {...commonTextInputProps}
          className={style.input}
          inputType="search"
          ariaAttributes={{ label: placeholder }}
          id={`search-box-input-${id}`}
          dataTestId="search-box-input"
          isDisabled={isDisabled}
          maxLength={100}
          onBlur={onBlur}
          onClick={onClick}
          onDblClick={onDblClick}
          onFocus={onFocus}
          onInput={handleInput}
          onValueChange={handleValueChange}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          readOnly={isDisabled}
          ref={inputRef}
          value={value}
          name="q"
        />
      </div>
      {shouldDisplayClearButton && (
        <ClearButton onClick={clearValue} label={clearButtonLabel} />
      )}
    </div>
  );
};
