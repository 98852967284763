import * as React from 'react';
import { IPopupContainerProps } from '../PopupContainer.types';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { getDataAttributes } from '../../../core/commons/utils';

const PopupContainer: React.FC<IPopupContainerProps> = props => {
  const { id, fillLayers, children, className } = props;
  const sdkEventHandlers = {
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={className}
      {...sdkEventHandlers}
    >
      <FillLayers {...fillLayers} />
      {children()}
    </div>
  );
};

export default PopupContainer;
