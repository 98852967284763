import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { ISignatureInputControllerActions } from '../SignatureInput.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ISignatureInputControllerActions => ({
  onValueChange: value => {
    updateProps({
      value,
    });
  },
});

export default mapActionsToProps;
