import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  PinterestPinItDefinition,
  PinterestPinItProps,
} from '../PinterestPinIt.types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import { props as viewerPropsMapper } from './PinterestPinIt.mapper';

const props = composeMapper<
  PreviewWrapperProps<PinterestPinItProps>,
  PinterestPinItDefinition
>()(
  viewerPropsMapper,
  {
    add: ['isSocialInteractionAllowed'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { isSocialInteractionAllowed } = dependencies;
    return {
      ...viewerProps,
      previewWrapperProps: getSocialCompBlockingLayerProps(
        isSocialInteractionAllowed,
      ),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
