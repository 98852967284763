import * as React from 'react';
import classNames from 'classnames';
import { IResponsivePopupContainerProps } from '../ResponsivePopupContainer.types';
import style from '../../ResponsivePopupPage/viewer/style/ResponsivePopupPage.scss';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import { getDataAttributes } from '../../../core/commons/utils';

const ResponsivePopupContainer: React.FC<IResponsivePopupContainerProps> =
  props => {
    const {
      id,
      className,
      containerProps,
      containerRootClassName,
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
    } = props;
    return (
      <div
        id={id}
        {...getDataAttributes(props)}
        className={classNames(className, containerRootClassName)}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classNames(style.bg)}
          data-testid={TestIds.ResponsivePopupContainerBg}
        ></div>
        <ResponsiveContainer {...containerProps}>
          {children}
        </ResponsiveContainer>
      </div>
    );
  };

export default ResponsivePopupContainer;
