import * as React from 'react';
import { ChevronRight, ChevronLeft } from '@wix/wix-ui-icons-common/on-stage';
import { st, classes } from './style/TabsScrollButton.st.css';

type IScrollButtonProps = {
  onClick: () => void;
  isVisible?: boolean;
  className: string;
  dataHook: string;
  Icon: typeof ChevronRight | typeof ChevronLeft;
};

type IDirectionalScrollButtonProps = Omit<IScrollButtonProps, 'Icon'> & {
  rtl?: boolean;
};

const ScrollButton: React.FC<IScrollButtonProps> = ({
  isVisible = false,
  onClick,
  className,
  dataHook,
  Icon,
}) => (
  <div
    className={st(classes.root, { isVisible }, className)}
    onClick={onClick}
    data-hook={dataHook}
  >
    <Icon className={classes.icon} />
  </div>
);

export const LeftScrollButton: React.FC<IDirectionalScrollButtonProps> = ({
  rtl,
  ...props
}) => {
  const Icon = !rtl ? ChevronLeft : ChevronRight;
  return <ScrollButton {...props} Icon={Icon} />;
};

export const RightScrollButton: React.FC<IDirectionalScrollButtonProps> = ({
  rtl,
  ...props
}) => {
  const Icon = !rtl ? ChevronRight : ChevronLeft;
  return <ScrollButton {...props} Icon={Icon} />;
};

export default ScrollButton;
