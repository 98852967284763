
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PopoverMenu1941626580",
      {"classes":{"root":"PopoverMenu1941626580__root","button":"PopoverMenu1941626580__button","label":"StylableButton2545352419__label","icon":"StylableButton2545352419__icon","text":"ListItemAction1895411377__text"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  