import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import React, { useRef } from 'react';
import {
  RatingsInputPreviewWrapperProps,
  IRatingsInputProps,
  IRatingsInputImperativeActions,
} from '../RatingsInput.types';
import { DEFAULT_SHAPE_SPACING, DEFAULT_SHAPE_SIZE } from './constants';
import { classes } from './RatingsInputItem/RatingsInputItem.component.st.css';

const noop = () => {};

function withComponentPreview(
  WrappedComponent: React.ComponentType<IRatingsInputProps>,
): IComponentPreviewWrapper<
  IRatingsInputProps,
  RatingsInputPreviewWrapperProps
> {
  return React.forwardRef<IRatingsInputImperativeActions, IRatingsInputProps>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        IRatingsInputProps,
        RatingsInputPreviewWrapperProps
      >,
      ref,
    ) => {
      const {
        shouldResetComponent,
        compPreviewState,
        componentViewMode,
        shapeSize = DEFAULT_SHAPE_SIZE,
        shapeSpacing = DEFAULT_SHAPE_SPACING,
        labelMargin = 0,
        rtl,
      } = previewWrapperProps;
      const {
        id,
        value,
        labelPosition,
        showTitle,
        showLabels,
        onRatingChange = noop,
        setValidityIndication = noop,
        validateValue = noop,
        a11yExperiment,
      } = viewerProps;
      const isValid = viewerProps.isValid || componentViewMode === 'editor';
      const rootMinWidth =
        a11yExperiment && labelPosition !== 'side'
          ? shapeSize * 5 + shapeSpacing * 4 + labelMargin
          : undefined;

      const getRating = () => {
        if (componentViewMode === 'editor' && compPreviewState === 'error') {
          return 0;
        }

        return componentViewMode === 'editor' &&
          (value === null || value === undefined)
          ? 3
          : value;
      };

      const previousCompViewMode = usePrevious(componentViewMode);
      const defaultValue = useRef(value);

      React.useEffect(() => {
        if (
          previousCompViewMode === 'editor' &&
          componentViewMode === 'editor'
        ) {
          defaultValue.current = value;
        }
      }, [previousCompViewMode, componentViewMode, value]);

      usePreviewEffect({
        componentViewMode: componentViewMode || '',
        onPreviewViewMode: validateValue,
      });

      const key = useResetComponent({
        shouldResetComponent,
        id,
        onResetComponent: () => {
          onRatingChange({
            target: { value: `${defaultValue.current}` },
          } as React.ChangeEvent<HTMLInputElement>);
          setValidityIndication(false);
          validateValue();
        },
      });

      usePreviewState(id, compPreviewState, {
        focus: {
          selectors: `.${classes.root}`,
          type: 'plural',
          state: 'focus-visible',
        },
        error: {
          selectors: `.${classes.root}`,
          type: 'plural',
        },
      });

      const NUM_OF_SHAPES = 5;
      const NUM_OF_SPACES_BETWEEN_SHAPES = 6;
      const calcIconsLayout = React.useCallback(
        (labelWidth: number) => {
          let horizontalMargins = {};

          if (labelPosition === 'side') {
            if (showTitle || showLabels) {
              horizontalMargins = {
                [rtl ? 'marginLeft' : 'marginRight']: Math.max(
                  15,
                  shapeSpacing,
                ),
              };
            }

            return {
              minWidth: `${
                NUM_OF_SPACES_BETWEEN_SHAPES * shapeSpacing +
                NUM_OF_SHAPES * shapeSize +
                labelWidth
              }px`,
              ...horizontalMargins,
            };
          }

          return {};
        },
        [shapeSize, shapeSpacing, labelPosition, showLabels, showTitle, rtl],
      );

      const reCalculateIconsSizeDeps = React.useMemo(
        () => [shapeSize, shapeSpacing, labelPosition, showLabels, showTitle],
        [shapeSize, shapeSpacing, labelPosition, showLabels, showTitle],
      );

      return (
        <WrappedComponent
          {...viewerProps}
          key={key}
          ref={ref}
          value={getRating()}
          showLabels={showLabels && compPreviewState !== 'error'}
          isValid={isValid}
          reCalculateIconsSizeDeps={reCalculateIconsSizeDeps}
          overrideIconsStyle={calcIconsLayout}
          rootMinWidth={rootMinWidth}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<IRatingsInputProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
