import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { ProgressBarDefinition, ProgressBarProps } from '../ProgressBar.types';
import { props as viewerPropsMapper } from './ProgressBar.mapper';

const EDITOR_DEFAULT_TARGET_VALUE = 100;
const EDITOR_DEFAULT_VALUE = 60;

const previewStatesForDefaults: Array<string> = ['layoutPanel', 'regular'];

const props = composeMapper<
  PreviewWrapperProps<ProgressBarProps>,
  ProgressBarDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    let viewerProps = resolver(deps, carmiData);
    if (
      deps.compPreviewState &&
      previewStatesForDefaults.includes(deps.compPreviewState)
    ) {
      viewerProps = {
        ...viewerProps,
        value: EDITOR_DEFAULT_VALUE,
        targetValue: EDITOR_DEFAULT_TARGET_VALUE,
      };
    }
    return {
      ...viewerProps,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
