import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  SlideShowContainerDefinition,
  SlideShowContainerCSSVars,
  SlideShowContainerProps,
  SlideShowCarmiData,
} from '@wix/thunderbolt-components-native';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './SlideShowContainer.mapper';

const props = composeMapper<
  PreviewWrapperProps<SlideShowContainerProps>,
  SlideShowContainerDefinition,
  SlideShowCarmiData
>()(
  viewerPropsMapper,
  {
    add: [
      'shouldResetComponent',
      'isComponentTransitionAllowed',
      'isPlayingAllowed',
      'componentViewMode',
      'compPreviewState',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const {
      isComponentTransitionAllowed,
      shouldResetComponent,
      isPlayingAllowed,
      componentViewMode,
      compPreviewState,
    } = dependencies;
    return {
      ...viewerProps,
      transitionDuration: isComponentTransitionAllowed
        ? viewerProps.transitionDuration
        : 0,
      autoPlay: isPlayingAllowed ? viewerProps.autoPlay : false,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
      },
    };
  },
);

const css = composeMapper<
  SlideShowContainerCSSVars,
  SlideShowContainerDefinition
>()(
  viewerCssMapper,
  { add: ['isComponentTransitionAllowed'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const previewCss: Partial<SlideShowContainerCSSVars> = {};
    if (!dependencies.isComponentTransitionAllowed) {
      previewCss['--transition-duration'] = '0';
    }
    return { ...viewerCss, ...previewCss };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
