import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { IPinItPinWidgetProps } from '../PinItPinWidget.types';
import { useIFrame } from '../../../providers/useIFrame/useIFrame';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/PinItPinWidget.scss';
import pinterestWidget from '!!url-loader?limit=1!./assets/pinterestWidget.html';

type StyleDimensions = {
  width?: string;
  height?: string;
};

const PinItPinWidget: React.FC<IPinItPinWidgetProps> = props => {
  const { id, className, pinUrl, onMouseEnter, onMouseLeave } = props;

  const iframeSrc = `${pinterestWidget}?${new URLSearchParams({
    pinUrl,
    compId: id,
  })}`;

  const [styles, setStyles] = useState<StyleDimensions>({});
  const [render, setRender] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleMessage = useCallback(
    event => {
      if (event.type !== 'message' || typeof event.payload !== 'string') {
        return;
      }
      try {
        const data = JSON.parse(event.payload);
        if (data.type !== 'pinterest' || data.compId !== id) {
          return;
        }
        if (data.showError) {
          setShowError(true);
          setStyles({ width: '225px', height: '274px' });
        } else {
          setShowError(false);
          setStyles({ width: `${data.width}px`, height: `${data.height}px` });
        }
      } catch (error) {}
    },
    [id],
  );

  const [ref] = useIFrame({ reducer: handleMessage });

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={className}
      style={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            className={classNames(style.iframe, {
              [style.iframeError]: showError,
            })}
            data-src={iframeSrc}
            ref={ref}
          />
          {showError && <div className={style.error} />}
        </wix-iframe>
      )}
    </div>
  );
};

export default PinItPinWidget;
