import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { WixProGallery as BaseGallery } from '@wix/pro-gallery-wix-wrapper';
import {
  processProGalleryOptions,
  flattenObject,
} from '@wix/pro-gallery-native-lib';
// TODO - migrate to a shared place instead of using deep import
import {
  debounce,
  getDataAttributes,
} from '@wix/thunderbolt-elements/dist/core/commons/utils';
import { getQaDataAttributes } from '@wix/thunderbolt-elements/dist/core/commons/qaUtils';
import { useResizeObserver } from '@wix/thunderbolt-elements/dist/providers/useResizeObserver/useResizeObserver';
import { IProGalleryProps, GalleryViewMode } from '../ProGallery.types';
import { isEditingHover, formatItemUri, isSSR } from '../common/utils';
import getExperimentalFeatures from '../common/getExperimentalFeatures';
import FullscreenGallery from './FullScreenGallery/FullScreenGallery';

import { st, classes } from './style/ProGallery.component.st.css';
import './style/ProGallery.global.scss';
import { useOptions } from './useOptions';

const RESIZE_OBSERVER_DELAY = 100;

const ProGallery: React.FC<IProGalleryProps> = props => {
  const {
    id,
    items,
    wixSDKItems,
    itemsSrc = 'organizeMedia',
    className,
    stylableClassName,
    forceState = {},
    onItemClicked = () => {},
    onMouseEnter,
    onMouseLeave,
    imageOnClickAction,
    isQaMode,
    fullNameCompType,
    shouldUseNewInfoElements,
    viewMode,
    deviceType,
    styleId,
    editorLayoutHeight,
    editorLayoutWidth,
    manualStyleParams,
  } = props;
  const isPrerender = isSSR();
  const defaultContainer = {
    scrollBase: 0,
    isDefaultContainer: true,
    width: props.options.responsive ? 980 : editorLayoutWidth,
    height: props.options.responsive ? 500 : editorLayoutHeight,
  };
  const isExperimentOpen = (name: string) => !!props.experiments?.[name];
  const containerRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<any>(defaultContainer);
  const [height, setHeight] = useState<number>(container.height);
  const [fullscreenIdx, setFullscreenIdx] = useState<number>(-1);
  useEffect(
    () => setHeight(editorLayoutHeight || height),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorLayoutHeight],
  );

  const { options } = useOptions({
    compProperties: props.options,
    element: containerRef.current || undefined,
    isExperimentOpen,
    styleId,
  });

  const styles = useMemo(
    () => ({
      ...processProGalleryOptions(options),
      alwaysShowHover: isEditingHover(forceState),
      ...(imageOnClickAction && { itemClick: imageOnClickAction }),
      ...flattenObject(manualStyleParams),
    }),
    [options, forceState, imageOnClickAction, manualStyleParams],
  );

  const eventsListener = useCallback(
    (eventName: string, eventData: any) => {
      switch (eventName) {
        case 'ITEM_ACTION_TRIGGERED':
          if (styles.itemClick === 'expand') {
            setFullscreenIdx(eventData.idx);
          }
          break;
        case 'ITEM_CLICKED':
          onItemClicked({
            itemIndex: eventData.idx,
            type: eventData.type,
          });
          break;
        default:
        // console.log({ eventName, eventData });
      }
    },
    [styles, onItemClicked],
  );

  const resizeGallery = useCallback(() => {
    setContainer({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
    });
  }, [containerRef]);

  const resizeObserverCallback = useMemo(
    () => debounce(resizeGallery, RESIZE_OBSERVER_DELAY),
    [resizeGallery],
  );

  useResizeObserver({
    ref: containerRef,
    callback: resizeObserverCallback,
  });

  const _items = useMemo(() => {
    return (
      (itemsSrc === 'wixSDK' ? wixSDKItems : items.map(formatItemUri)) || []
    );
  }, [items, wixSDKItems, itemsSrc]);
  const ssrStyleOverride = isPrerender
    ? {
        minHeight: 'max-content',
        height: '100%',
      }
    : {};
  const classicHeight = props.options.responsive ? {} : { height };
  const experimentalFeatures = getExperimentalFeatures(isExperimentOpen);
  if (shouldUseNewInfoElements) {
    experimentalFeatures.newInfoElements = shouldUseNewInfoElements;
  }
  return (
    <div
      id={id}
      className={st(classes.container, className)}
      style={{
        ...classicHeight,
        ...ssrStyleOverride,
      }}
      {...getQaDataAttributes(isQaMode, fullNameCompType)}
      {...getDataAttributes(props)}
    >
      <div
        className={st(classes.root, stylableClassName)}
        ref={containerRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <BaseGallery
          experimentalFeatures={experimentalFeatures}
          id={id}
          itemsSrc={itemsSrc}
          items={_items}
          styles={styles}
          container={container || defaultContainer}
          eventsListener={eventsListener}
          scrollingElement={containerRef.current as any}
          deviceType={deviceType!}
          isMobile={deviceType === 'mobile'}
          staticMediaUrls={undefined as any}
          viewMode={viewMode as GalleryViewMode}
          setLayoutHeightImp={setHeight}
        />
      </div>
      <FullscreenGallery
        id={id}
        items={_items}
        itemsSrc={itemsSrc}
        deviceType={deviceType}
        viewMode={viewMode}
        activeIndex={fullscreenIdx}
        styleId={styleId}
        onClose={() => setFullscreenIdx(-1)}
      />
    </div>
  );
};

export default ProGallery;
