import { WixAtlasServiceWeb } from '@wix/ambassador-wix-atlas-service-web/http';
import { withComponentController } from '@wix/editor-elements-integrations';
import {
  GetPlaceFunction,
  IAddressInputRefState,
  PredictFunction,
  IAddressInputProps,
  AddressInputMapperProps,
  IAddressInputControllerProps,
} from '../AddressInput.types';

const metasiteAppDefinitionId = '22bef345-3c5b-4c18-b782-74d4085112ff';
const BASE_LINGUIST_HEADER =
  '|en-us|false|4e8a573a-6b3e-426f-9d2f-5285b7dc90f8';

const getAtlasServiceUrl = (externalBaseUrl: string, domain: string) => {
  const isWixDomain = domain
    ? domain.includes('wix.com')
    : externalBaseUrl.includes('wix.com');

  const url = isWixDomain ? '' : externalBaseUrl;
  const atlasWebBaseURL = isWixDomain
    ? '/wix-atlas-service-web'
    : '/_api/wix-atlas-service-web';

  return `${url}${atlasWebBaseURL}`;
};

const getPlace: GetPlaceFunction = ({
  PlacesServiceV2,
  lang,
  request,
  metaSiteInstance,
}) => {
  return PlacesServiceV2()({
    Authorization: metaSiteInstance,
    'x-wix-linguist': `${lang}${BASE_LINGUIST_HEADER}`,
  }).getPlace(request);
};

const predict: PredictFunction = ({
  AutocompleteServiceV2,
  lang,
  metaSiteInstance,
  request,
}) => {
  return AutocompleteServiceV2()({
    Authorization: metaSiteInstance,
    'x-wix-linguist': `${lang}${BASE_LINGUIST_HEADER}`,
  }).predict(request);
};

const compController = withComponentController<
  AddressInputMapperProps,
  IAddressInputRefState,
  IAddressInputControllerProps,
  Partial<IAddressInputProps>
>(({ stateValues, mapperProps, controllerUtils }) => {
  const { getAppInstanceByAppDefId, domain, reportBi } = stateValues;
  const { lang, externalBaseUrl, ...restProps } = mapperProps;
  const { updateProps } = controllerUtils;

  const { origin } = new URL(externalBaseUrl);

  const metaSiteInstance =
    getAppInstanceByAppDefId(metasiteAppDefinitionId) || '';

  const atlasServiceUrl = getAtlasServiceUrl(origin, domain);

  const { AutocompleteServiceV2, PlacesServiceV2 } =
    WixAtlasServiceWeb(atlasServiceUrl);

  return {
    ...restProps,
    lang,
    getPlace: request =>
      getPlace({
        PlacesServiceV2,
        request,
        lang,
        metaSiteInstance,
      }),
    predict: request =>
      predict({
        AutocompleteServiceV2,
        request,
        lang,
        metaSiteInstance,
      }),
    onValueChange: value => updateProps({ value }),
    onBI: (event, extra = {}) => reportBi(event, extra),
    setValidityIndication: shouldShowValidityIndication => {
      updateProps({
        shouldShowValidityIndication,
      });
    },
    onSuggestionsUpdate: updatedSuggestions =>
      updateProps({ suggestions: updatedSuggestions }),
    onSuggestionsVisibilityUpdate: updatedSuggestionsVisibility =>
      updateProps({ suggestionsVisibility: updatedSuggestionsVisibility }),
  };
});

export default compController;
