import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  SoundCloudWidgetDefinition,
  SoundCloudWidgetProps,
} from '../SoundCloudWidget.types';
import { props as viewerPropsMapper } from './SoundCloudWidget.mapper';

const props = composeMapper<
  PreviewWrapperProps<SoundCloudWidgetProps>,
  SoundCloudWidgetDefinition
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed', 'shouldResetComponent'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      autoPlay: deps.isPlayingAllowed && viewerProps.autoPlay,
      previewWrapperProps: {
        shouldResetComponent: deps.shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
