import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { VerticalMenuDefinition } from '@wix/thunderbolt-components';
import { VerticalMenuProps } from '../VerticalMenu.types';

export const props = withCompInfo<
  VerticalMenuProps,
  VerticalMenuDefinition,
  any
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
