import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import type { Direction } from '@wix/editor-elements-types/thunderbolt';
import { itemsAlignmentMap } from '../constants';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import {
  ITabsCarmiData,
  ITabsMapperProps,
  TabsAlignment,
  TabsMenuMode,
} from '../Tabs.types';

export const props = withCompInfo<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'hasResponsiveLayout',
    'styleProperties',
  ],
  (
    {
      compData,
      compProps,
      compStylableClass,
      hasResponsiveLayout,
      styleProperties,
    },
    carmiData,
  ) => {
    const { tabItems, containerProps, containerRootClassName } = carmiData;
    const { defaultTabId } = compData;

    return {
      ...compData,
      ...compProps,
      menuMode: styleProperties.menuMode as TabsMenuMode,
      itemsDirection: styleProperties.itemsDirection as Direction,
      stylableClassName: compStylableClass,
      tabItems,
      currentTabId: defaultTabId,
      hasResponsiveLayout,
      containerProps,
      containerRootClassName,
    };
  },
);
const toPxValue = (val: string | number) => `${val}px`;
type MarginCSSValue = number | 'auto';
type JustifyContentValue = 'flex-start' | 'flex-end' | 'normal' | 'center';

type FlexCssVars = {
  '--first-child-margin-left': MarginCSSValue;
  '--first-child-margin-right': MarginCSSValue;
  '--last-child-margin-left': MarginCSSValue;
  '--last-child-margin-right': MarginCSSValue;
};
type MenuModeCSSVars = {
  '--tabs-list-justify-content':
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'normal';
  '--tabs-list-overflow-x': 'visible' | 'auto';
  '--tabs-list-flex-wrap': 'wrap' | 'nowrap';
};
const getMenuModeCSSVars = (
  menuMode: TabsMenuMode,
  itemsAlignment: TabsAlignment,
  itemsDirection: Direction,
): MenuModeCSSVars => {
  if (menuMode === 'scroll') {
    return {
      '--tabs-list-overflow-x': 'auto',
      '--tabs-list-flex-wrap': 'nowrap',
      '--tabs-list-justify-content': 'normal',
    };
  } else {
    let justifyContent: JustifyContentValue = 'normal';
    if (itemsAlignment === 'center') {
      justifyContent = 'center';
    } else if (itemsAlignment === 'left') {
      justifyContent = itemsDirection === 'ltr' ? 'flex-start' : 'flex-end';
    } else {
      justifyContent = itemsDirection === 'rtl' ? 'flex-start' : 'flex-end';
    }
    return {
      '--tabs-list-overflow-x': 'visible',
      '--tabs-list-flex-wrap': 'wrap',
      '--tabs-list-justify-content': justifyContent,
    };
  }
};
const getTabsListAlignmentCssVars = (
  menuMode: TabsMenuMode,
  itemsAlignment: TabsAlignment,
  itemsDirection: Direction,
): FlexCssVars => {
  let firstChildMarginLeft: MarginCSSValue = 0;
  let firstChildMarginRight: MarginCSSValue = 0;
  let lastChildMarginLeft: MarginCSSValue = 0;
  let lastChildMarginRight: MarginCSSValue = 0;

  if (menuMode === 'scroll') {
    if (itemsDirection === 'ltr') {
      if (itemsAlignment === 'center') {
        firstChildMarginLeft = 'auto';
        lastChildMarginRight = 'auto';
      } else if (itemsAlignment === 'right') {
        firstChildMarginLeft = 'auto';
      }
    } else {
      if (itemsAlignment === 'center') {
        firstChildMarginRight = 'auto';
        lastChildMarginLeft = 'auto';
      } else if (itemsAlignment === 'right') {
        lastChildMarginLeft = 'auto';
      } else {
        firstChildMarginRight = 'auto';
      }
    }
  }
  return {
    '--first-child-margin-left': firstChildMarginLeft,
    '--first-child-margin-right': firstChildMarginRight,
    '--last-child-margin-left': lastChildMarginLeft,
    '--last-child-margin-right': lastChildMarginRight,
  };
};

export const css = withCompInfo<any, ITabsDefinition, never>()(
  ['compProps', 'hasResponsiveLayout', 'styleProperties'],
  ({ compProps, hasResponsiveLayout, styleProperties }) => {
    const {
      menuMode = compProps.menuMode,
      itemsDirection = compProps.itemsDirection,
      itemsAlignment = compProps.itemsAlignment,
      containerSpacing = compProps.containerSpacing,
      itemSpacing = compProps.itemSpacing,
      contentAlignment = compProps.contentAlignment,
      horizontalTabPadding = compProps.horizontalTabPadding,
      verticalTabPadding = compProps.verticalTabPadding,
      stretchTabsToMenuWidth = compProps.stretchTabsToMenuWidth,
      itemRowSpacing = compProps.itemRowSpacing,
    } = styleProperties as Partial<typeof compProps>;
    const responsiveContainerVars = {
      '--rd': '0',
      '--brw': '0',
      '--shd': 'none',
      '--bg': 'transparent',
    };

    const tabsListAlignmentCSSVars = getTabsListAlignmentCssVars(
      menuMode,
      itemsAlignment,
      itemsDirection,
    );

    const tabsListLayoutCSSVars = {
      '--tabs-list-items-gap': toPxValue(itemSpacing),
      '--tabs-list-container-gap': toPxValue(containerSpacing),
      '--tabs-list-content-alignment': itemsAlignmentMap[contentAlignment],
      '--tabs-list-horizontal-padding': toPxValue(horizontalTabPadding),
      '--tabs-list-vertical-padding': toPxValue(verticalTabPadding),
      '--tabs-list-item-flex-grow': stretchTabsToMenuWidth ? '1' : '0',
      '--tabs-list-wrap-row-gap': toPxValue(itemRowSpacing),
    };
    const menuModeCSSVars = getMenuModeCSSVars(
      menuMode,
      itemsAlignment,
      itemsDirection,
    );

    return {
      height: 'auto',
      ...(hasResponsiveLayout ? responsiveContainerVars : {}),
      ...tabsListLayoutCSSVars,
      ...tabsListAlignmentCSSVars,
      ...menuModeCSSVars,
    };
  },
);

export default createComponentMapperModel({ props, css });
