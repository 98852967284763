import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { CollapsibleTextControllerActions } from '../CollapsibleText.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): CollapsibleTextControllerActions => ({
  setIsExpanded: (isExpanded: boolean) => {
    updateProps({ isExpanded });
  },
});

export default mapActionsToProps;
