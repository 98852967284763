import React from 'react';
import { ITinyMenuProps, SkinType } from '../../../TinyMenu.types';
import TinyMenu from '../../TinyMenu';
import { SkinTinyMenuProps } from '../SkinTinyMenu';
import TinyMenuFullScreenSkin from './styles/TinyMenuFullScreenSkin.scss';
import TinyMenuPullFromLeftSkin from './styles/TinyMenuPullFromLeftSkin.scss';
import TinyMenuPullFromRightSkin from './styles/TinyMenuPullFromRightSkin.scss';
import TinyMenuPullFromTopSkin from './styles/TinyMenuPullFromTopSkin.scss';
import BaseMenu from './BaseMenu';

const skinStyles: Record<SkinType, Record<string, string>> = {
  TinyMenuFullScreenSkin,
  TinyMenuPullFromLeftSkin,
  TinyMenuPullFromRightSkin,
  TinyMenuPullFromTopSkin,
  TinyMenuSkin: {},
};

const getShouldCloseOnSwipe = (skinType: SkinType) => {
  if (skinType === 'TinyMenuPullFromLeftSkin') {
    return 'left';
  }
  if (skinType === 'TinyMenuPullFromTopSkin') {
    return 'right';
  }
  return undefined;
};

const Skin: React.FC<SkinTinyMenuProps> = props => {
  const { skinType = 'TinyMenuFullScreenSkin' } = props.wrapperProps;
  const shouldCloseOnSwipe = getShouldCloseOnSwipe(skinType);
  return (
    <BaseMenu
      {...props}
      skinsStyle={skinStyles[skinType]}
      shouldCloseOnSwipe={shouldCloseOnSwipe}
    />
  );
};

const TinyMenuDefaultSkin: React.FC<
  ITinyMenuProps & {
    skin: SkinType;
  }
> = props => <TinyMenu {...props} skin={Skin} skinType={props.skin} />;

export default TinyMenuDefaultSkin;
