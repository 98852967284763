import * as React from 'react';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { BasicContainer } from '../../shared/BasicContainer';

// Migrated from https://github.com/wix-private/santa-core/blob/741568ec5f02426413e23d9be6c37edd80ec85a8/santa-components/src/components/Container/skins/wysiwyg.viewer.skins.area.CleanZoomAreaSkin.scss

// It's just a simple containerm no styles
const CleanZoomAreaSkin: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return <BasicContainer ref={ref} {...props} classes={{}} />;
};

export default React.forwardRef(CleanZoomAreaSkin);
