import * as React from 'react';
import { RawItem, Item } from '../../types';

export const populateWithSlot = (
  items: Array<RawItem> = [],
  slots: Record<string, React.ReactNode>,
): Array<Item> =>
  items.map(item => ({
    ...item,
    content: slots[item.slot] || 'Error: empty slot',
  }));
