import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ICollapsibleTextMapperProps,
  CollapsibleTextDefinition,
  ButtonAlignment,
} from '../CollapsibleText.types';

const getAlignSelf = (alignButton: ButtonAlignment) =>
  alignButton === 'center'
    ? 'center'
    : alignButton === 'right'
    ? 'flex-end'
    : 'flex-start';

export const props = withCompInfo<
  Partial<ICollapsibleTextMapperProps>,
  CollapsibleTextDefinition
>()(
  ['compData', 'compProps', 'compStylableClass', 'isConnectedToDataBinding'],
  ({
    compData,
    compProps,
    compStylableClass: stylableClassName,
    isConnectedToDataBinding,
  }) => ({
    ...compData,
    ...(isConnectedToDataBinding ? { tag: 'div' } : {}),
    ...compProps,
    stylableClassName,
    // TODO: Change when correct type is added to TB
    link: compData.link ? (compData.link as any).resolvedLink : null,
  }),
);

export const css = withCompInfo()(
  ['compProps', 'compLayout'],
  ({ compProps: { maxLines, expandControlSpacing, alignButton } }) => {
    const alignSelf = getAlignSelf(alignButton);
    return {
      height: 'auto',
      '--max-lines': maxLines,
      '--expand-control-spacing': `${expandControlSpacing}px`,
      '--expand-control-align': `${alignSelf}`,
    };
  },
);

export default createComponentMapperModel({
  props,
  css,
});
