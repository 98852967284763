import * as React from 'react';
import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';

const mapActionsToProps: IComponentController = ({ updateProps }) => ({
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
    updateProps({
      checked: event.target.checked,
    });
  },
});

export default mapActionsToProps;
