export const ViewerType = 'wixui.AccordionContainer';
export const PropertiesType = 'AccordionContainerProperties';
export const DataType = 'AccordionContainerData';

export enum DefaultOpened {
  CLOSE_ALL = 'close_all',
  OPEN_FIRST = 'open_first',
  OPEN_ALL = 'open_all',
}

export const defaultOpenedVariants = Object.values(DefaultOpened);
