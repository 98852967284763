export const ViewerType = 'wixui.Tabs' as const;
export const PropertiesType = 'TabsProperties' as const;
export const TabItemClickedEventType = 'OnTabItemClicked' as const;

export const INITIAL_DEFAULT_TAB = 'FIRST_TAB' as const;
export const COMP_NAME = 'Tabs' as const;
export const TAB_REFERENCES_PROPERTY = 'tabReferences' as const;
export const SINGLE_TAB_REFERENCE_PROPERTY = 'tabReference' as const;

export const TranslationKeys = {
  gfppHelpId: 'help.gfpp-Tabs',
  manageItemsGfppAction: 'tabs_main_GFPP_Manage',
} as const;

export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
} as const;

export const TestIds = {
  AddPanel: 'tabs-add-panel-section',
  ManageItemsAction: 'tabs-manage-items-panel-gfpp-action',
  ManageItemsPanelContainer: 'tabs-manage-items-panel-container',
  SortDragList: 'SortDragList',
  AddTabButton: 'AddTabButton',
  DuplicateTabButton: 'DuplicateTabButton',
  EditTabNameMenuItem: 'EditTabNameMenuItem',
  DuplicateTabMenuItem: 'DuplicateTabMenuItem',
  DeleteTabMenuItem: 'DeleteTabMenuItem',
  TabItem: 'tab-item',
  TabItemLabel: 'tab-item-label',
  ForwardScrollBtn: 'ForwardScrollBtn',
  BackwardScrollBtn: 'BackwardScrollBtn',
  TabsList: 'TabsListWrapper',
  ActiveTabItem: 'ActiveTabItem',
  TabPanel: 'TabPanel',
} as const;

export const selectors = {
  root: '.root',
  tabsList: '.root::tabsList',
  tabsListItem: '.root::tabsList::tab',
  tabLabel: '.root::tabsList::label',
  scrollButton: '.root::tabsList::scrollButton',
  scrollButtonIcon: '.root::tabsList::scrollButton::icon',
  hoveredScrollButtonIcon: '.root::tabsList::scrollButton:hover::icon',
  tabContainer: '.root::multiStateBoxWrapper',
};

export enum MenuMode {
  wrap = 'wrap',
  scroll = 'scroll',
}

export const itemsAlignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;
