
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SingleTabcomponent2992125346",
      {"classes":{"root":"SingleTabcomponent2992125346__root"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  