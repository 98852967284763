import { PageTransition } from '../../PaginatedGridGallery.types';
import * as CrossFade from './CrossFade.scss';
import * as Shrink from './ShrinkPage.scss';
import * as SlideHorizontal from './SlideHorizontal.scss';
import * as SlideVertical from './SlideVertical.scss';

type transitionClasses = Record<string, string>;

const transitionsStyles: Record<PageTransition['name'], transitionClasses> = {
  CrossFade,
  Shrink,
  SlideHorizontal,
  SlideVertical,
  NoTransition: {},
};

export default transitionsStyles;
