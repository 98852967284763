import * as React from 'react';
import SiteButton from '../../SiteButton';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import ButtonForkedSkin from './ButtonForkedSkin';
import skinsStyle from './styles/ButtonForkedLeft.scss';

const ButtonForkedLeftSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>((props, ref) => (
  <ButtonForkedSkin
    {...props}
    skinsStyle={skinsStyle}
    ref={ref}
  ></ButtonForkedSkin>
));

const ButtonForkedLeft: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ButtonForkedLeftSkin} ref={ref} />
);

export default React.forwardRef(ButtonForkedLeft);
