import React from 'react';
import classnames from 'classnames';
import {
  ILinkContent,
  IVerticalAnchorsMenuProps,
} from '../../../VerticalAnchorsMenu.types';
import VerticalAnchorsMenuCommonSkin from '../VerticalAnchorsMenuCommonSkin';
import styles from './styles/skins.scss';

const linkContent = (props: ILinkContent) => {
  const { activeAnchor, id, label } = props;
  return (
    <>
      <svg
        className={classnames(styles.symbol, {
          [styles.activeAnchor]: activeAnchor,
        })}
        viewBox="0 0 48 48"
      >
        <circle cx="24" cy="24" r="22" />
        <circle cx="24" cy="24" r="10" />
        <circle cx="24" cy="24" r="10" />
      </svg>
      <span id={id} className={styles.hiddenText}>
        {label}
      </span>
    </>
  );
};

const VerticalAnchorsMenuLinkedNoTextSkin: React.FC<IVerticalAnchorsMenuProps> =
  props => {
    return (
      <VerticalAnchorsMenuCommonSkin
        {...props}
        style={styles}
        skin={'VerticalAnchorsMenuLinkedNoTextSkin'}
        linkContent={linkContent}
      />
    );
  };

export default VerticalAnchorsMenuLinkedNoTextSkin;
