import * as React from 'react';
import { IMatrixGalleryProps } from '../../../MatrixGallery.types';
import MatrixGallery from '../../MatrixGallery';
import skinsItemStyle from '../../../../MatrixGalleryItem/viewer/style/MatrixGalleryPolaroidSkinItem.scss';
import MatrixGalleryItemWithPanel from '../../../../MatrixGalleryItem/viewer/MatrixGalleryItemWithPanel';
import skinsStyle from './style/MatrixGalleryPolaroidSkin.scss';

const MatrixGalleryPolaroidSkin: React.FC<IMatrixGalleryProps> = props => {
  return (
    <MatrixGallery
      {...props}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      MatrixGalleryItemComponent={MatrixGalleryItemWithPanel}
    />
  );
};

export default MatrixGalleryPolaroidSkin;
