import { useEffect, useState } from 'react';
import { PREVIEW_STATE, PREVIEW_STATE_DELIMETER } from './constants';

export const useEditSlotId = (compPreviewState?: string | null): string => {
  const [slotId, setSlotId] = useState('');

  useEffect(() => {
    if (compPreviewState?.startsWith(PREVIEW_STATE.FREEZE_MEGA_MENU)) {
      setSlotId(compPreviewState.split(PREVIEW_STATE_DELIMETER).pop()!);
    }
    if (compPreviewState === PREVIEW_STATE.UNFREEZE_MEGA_MENU) {
      setSlotId('');
    }
  }, [compPreviewState]);

  return slotId;
};
