import * as React from 'react';
import { INoPermissionsToPageDialogProps } from '../NoPermissionsToPageDialog.types';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { NoPermissionsToPageTranslations, TestIds } from '../constants';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/NoPermissionsToPageDialog.scss';

const NoPermissionsToPageDialog: React.FC<INoPermissionsToPageDialogProps> =
  props => {
    const {
      id,
      onCloseDialogCallback,
      onSwitchAccountLinkClick,
      translate,
      className,
    } = props;

    return (
      <SiteMembersDialogLayout
        {...getDataAttributes(props)}
        isCloseable={false}
        onCloseDialogCallback={onCloseDialogCallback}
        className={className}
      >
        <div id={id} className={style.noPermissionsToPageDialog}>
          <h3 className={style.title}>
            {translate!(
              NoPermissionsToPageTranslations.NAMESPACE,
              NoPermissionsToPageTranslations.TITLE_KEY,
              NoPermissionsToPageTranslations.TITLE_DEFAULT,
            )}
          </h3>
          <div className={style.wrapper}>
            <div className={style.footer}>
              <div className={style.actionButton}>
                <BasicButton
                  id={`${TestIds.backToSiteButton}_${id}`}
                  label={translate!(
                    NoPermissionsToPageTranslations.NAMESPACE,
                    NoPermissionsToPageTranslations.BACK_TO_SITE_BTN_KEY,
                    NoPermissionsToPageTranslations.BACK_TO_SITE_BTN_DEFAULT,
                  )}
                  autoFocus={true}
                  isDisabled={false}
                  link={undefined}
                  hasPlatformClickHandler={true}
                  onClick={onCloseDialogCallback}
                />
              </div>
              <button
                id={`${TestIds.switchAccountLinkBtn}_${id}`}
                role="link"
                className={style.switchAccountLink}
                onClick={onSwitchAccountLinkClick}
              >
                {translate!(
                  NoPermissionsToPageTranslations.NAMESPACE,
                  NoPermissionsToPageTranslations.SWITCH_ACCOUNT_LINK_KEY,
                  NoPermissionsToPageTranslations.SWITCH_ACCOUNT_LINK_DEFAULT,
                )}
              </button>
            </div>
          </div>
        </div>
      </SiteMembersDialogLayout>
    );
  };

export default NoPermissionsToPageDialog;
