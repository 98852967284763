import React from 'react';
import { TestIds } from '../../constants';
import TinyMenuItem from '../TinyMenuItem';
import { ITinyMenuItemProps } from '../../TinyMenu.types';

export interface ITinyMenuMembersMenu {
  userName: string;
  loggedInText: string;
  logOutText: string;
  items: Array<ITinyMenuItemProps>;
  skinsStyle: Record<string, string>;
  onLogout(): void;
}

const createLogoutItem = ({
  logOutText,
  onLogout,
}: Pick<
  ITinyMenuMembersMenu,
  'logOutText' | 'onLogout'
>): ITinyMenuItemProps => ({
  label: logOutText,
  link: {},
  onClick: onLogout,
});

const getWelcomeText = (userName: string, loggedInText: string) => {
  return loggedInText ? `${loggedInText} ${userName}` : userName;
};

const TinyMenuMembersMenu: React.FC<ITinyMenuMembersMenu> = ({
  userName,
  logOutText,
  loggedInText,
  skinsStyle,
  items,
  onLogout,
}) => {
  const itemsWithLogout = [
    ...items,
    createLogoutItem({ logOutText, onLogout }),
  ];

  return (
    <TinyMenuItem
      dataTestId={TestIds.welcomeItem}
      label={getWelcomeText(userName, loggedInText)}
      skinsStyle={skinsStyle}
      items={itemsWithLogout}
    />
  );
};

export default TinyMenuMembersMenu;
