import { getDataAttributes } from '@wix/thunderbolt-elements/src/core/commons/utils';
import * as React from 'react';
import type { IMockLibCompProps } from '../MockLibComp.props';

const MockLibProp: React.FC<IMockLibCompProps> = props => {
  const { id, myProp, className } = props;

  return (
    <div className={className} {...getDataAttributes(props)} id={id}>
      <span>{`hello ${myProp}`}</span>
    </div>
  );
};

export default MockLibProp;
