import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  IAccordionItemDefinition,
  AccordionItemProps as PlatformProps,
} from '../types';

export const props = withCompInfo<
  PlatformProps,
  IAccordionItemDefinition,
  PlatformProps
>()([], (_, carmiData) => {
  return { ...carmiData };
});

export const css = withCompInfo()([], () => {
  return {
    '--rd': '0',
    '--brw': '0',
    '--shd': 'none',
    '--bg': 'transparent',
    width: '100%',
  };
});

const uiType = withCompInfo()(
  ['hasResponsiveLayout'],
  ({ hasResponsiveLayout }) =>
    (hasResponsiveLayout ? 'ResponsiveComponent' : 'ClassicComponent') as any,
);

export default createComponentMapperModel({ props, css, uiType });
