import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  DisqusCommentsDefinition,
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
} from '../DisqusComments.types';

export const props = withCompInfo<
  DisqusCommentsMapperProps,
  DisqusCommentsDefinition
>()(['compData'], ({ compData }) => {
  return { disqusId: compData.disqusId };
});

export const stateRefs = withStateRefsValues<DisqusCommentsStateRefs>([
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({ props, stateRefs });
