import * as React from 'react';
import { LinkProps } from '@wix/thunderbolt-components';
import classNames from 'classnames';
import {
  ExpandableMenuItemWithIsSelected,
  ExpandableMenuProps,
  ExpandableMenuItem as ExpandableMenuItemType,
} from '../ExpandableMenu.types';
import * as translations from '../translations/constants';
import { getDataAttributes, isEmptyObject } from '../../../core/commons/utils';
import style from './style/ExpandableMenu.scss';
import ExpandableMenuItem from './ExpandableMenuItem';

const isAnchorLink = (link: LinkProps) =>
  !!link.anchorDataId || !!link.anchorCompId;

const getIsActiveItem = (item: ExpandableMenuItemType, href: string) =>
  !!item.link && !isAnchorLink(item.link) && item.link.href === href;

const isFolder = (link: LinkProps | undefined) => isEmptyObject(link);

const getItemsWithIsSelected = (
  items: Array<ExpandableMenuItemType>,
  href = '',
): Array<ExpandableMenuItemWithIsSelected> => {
  return items.map(item => {
    const isActiveItem =
      item.selected === false
        ? false
        : item.selected || getIsActiveItem(item, href);
    const subItems = item.items || [];
    const subItemsWithIsSelected = getItemsWithIsSelected(subItems, href);
    const isActiveSubMenu = subItemsWithIsSelected.some(
      child => child.isSelected,
    );
    const isSelected =
      item.selected === false
        ? false
        : isActiveItem || (isActiveSubMenu && !isFolder(item.link));

    return {
      ...item,
      isSelected,
      items: subItemsWithIsSelected,
      isCurrent: isActiveItem,
    };
  });
};

const ExpandableMenu: React.FC<ExpandableMenuProps> = props => {
  const {
    id,
    className,
    items,
    currentPrimaryPageHref,
    translate,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    onMouseEnter,
    onMouseLeave,
    skin = 'ExpandableMenuSkin',
  } = props;
  const itemsWithIsSelected = React.useMemo(
    () => getItemsWithIsSelected(items, currentPrimaryPageHref),
    [items, currentPrimaryPageHref],
  );

  const translatedAriaLabel = translate!(
    translations.ARIA_LABEL_NAMESPACE,
    translations.ARIA_LABEL_KEY,
    translations.ARIA_LABEL_DEFAULT,
  );

  return (
    <nav
      id={id}
      {...getDataAttributes(props)}
      aria-label={translatedAriaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(style[skin], className)}
    >
      <ul className={style.menuContainer}>
        {itemsWithIsSelected.map((item, index) => (
          <ExpandableMenuItem
            key={index}
            {...item}
            onItemClick={onItemClick}
            onItemDblClick={onItemDblClick}
            onItemMouseIn={onItemMouseIn}
            onItemMouseOut={onItemMouseOut}
            idPrefix={`${id}-${index}`}
            skin={skin}
          />
        ))}
      </ul>
    </nav>
  );
};

export default ExpandableMenu;
