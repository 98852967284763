import React, { useEffect, useState, useCallback } from 'react';
import { IFlickrBadgeWidgetProps } from '../FlickrBadgeWidget.types';
import { useIFrame } from '../../../providers/useIFrame/useIFrame';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/FlickrBadgeWidget.scss';
import flickrBadgeWidget from '!!url-loader?limit=1!./assets/flickrBadgeWidget.html';

type StyleDimensions = {
  width?: string;
  height?: string;
};

const FlickrBadgeWidget: React.FC<IFlickrBadgeWidgetProps> = props => {
  const {
    id,
    className,
    imageCount,
    whichImages,
    imageSize,
    layoutOrientation,
    userId,
    tag,
    currentUrl,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const iframeSrc = `${flickrBadgeWidget}?${new URLSearchParams({
    compId: id,
    userId,
    tag,
    imageCount,
    whichImages,
    imageSize,
    layoutOrientation,
    origin: currentUrl,
  })}`;
  const href = `https://www.flickr.com/photos/${userId}/`;

  const [styles, setStyles] = useState<StyleDimensions>({});
  const [render, setRender] = useState(false);

  const handleMessage = useCallback(
    event => {
      if (event.type !== 'message' || typeof event.payload === 'string') {
        return;
      }
      const data = event.payload.data;
      if (data.compId !== id) {
        return;
      }
      setStyles({
        width: `${data.size.width}px`,
        height: `${data.size.height}px`,
      });
    },
    [id],
  );

  const [ref] = useIFrame({ reducer: handleMessage });

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={className}
      style={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          <a href={href} target="_blank" rel="noreferrer">
            <div className={style.overlay} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe data-src={iframeSrc} ref={ref} style={styles} />
        </wix-iframe>
      )}
    </div>
  );
};

export default FlickrBadgeWidget;
