import React from 'react';
import classNames from 'classnames';
import {
  ICustomElementPreviewComponentProps,
  ICustomElementImperativeActions,
} from '../CustomElementPreviewComponent.types';
import { useIFrame } from '../../../../providers/useIFrame/useIFrame';
import { getDataAttributes } from '../../../../core/commons/utils';
import styles from './CustomElementPreviewComponent.scss';
import customElementPreviewIframe from '!url-loader?limit=1!./assets/CustomElementPreviewIframe.html';

const enum Translations {
  UpgradePremium = 'custom_element_gfpp_upgrade_premium_info_text',
  UpgradeNoAds = 'custom_element_gfpp_upgrade_no_ads_info_text',
  ConnectDomain = 'Custom_element_gfpp_connect_domain_info_text',
  BlocksUpgrade = 'custom_element_gfpp_blocks_upgrade_premium_info_text',
}

const PremiumBanner: React.FC<
  Pick<
    ICustomElementPreviewComponentProps,
    'isPremiumUser' | 'hasDomainFeature' | 'translate' | 'isWixBlocks'
  >
> = props => {
  const { isWixBlocks, isPremiumUser, hasDomainFeature, translate } = props;

  const getTranslationKey = () => {
    if (isWixBlocks) {
      return Translations.BlocksUpgrade;
    }
    if (!isPremiumUser) {
      return Translations.UpgradePremium;
    }

    if (!hasDomainFeature) {
      return Translations.ConnectDomain;
    }

    return Translations.UpgradeNoAds;
  };

  const translationKey = getTranslationKey();

  return (
    <div className={styles['premium-banner']}>
      {translate?.('custom_element', translationKey, translationKey)}
    </div>
  );
};

const getAttributes = (
  attributes: ICustomElementPreviewComponentProps['attributes'],
  initialAttributes: ICustomElementPreviewComponentProps['initialAttributes'],
) => {
  const iframeAttributes = attributes || {};

  try {
    const parsedInitialAttributes = JSON.parse(initialAttributes);
    return Object.assign(parsedInitialAttributes, iframeAttributes);
  } catch (err) {
    return iframeAttributes;
  }
};

const CustomElementPreviewComponent: React.ForwardRefRenderFunction<
  ICustomElementImperativeActions,
  ICustomElementPreviewComponentProps
> = (props, ref) => {
  const {
    id,
    componentViewMode,
    isPremiumUser,
    hasDomainFeature,
    isAdsFree,
    isAllowedForFreeSite,
    translate,
    events: eventNames = [],
    tagName,
    handleEvents,
    containerSizes,
    previewUrl,
    scriptType,
    attributes,
    initialAttributes,
    isWixBlocks,
    className,
  } = props;

  const [canRenderIframe, setCanRenderIFrame] = React.useState(false);
  React.useEffect(() => setCanRenderIFrame(true), []);

  const iframeAttributes = React.useMemo(
    () => getAttributes(attributes, initialAttributes),
    [attributes, initialAttributes],
  );

  const handleMessage = React.useCallback(
    ({ type, payload }) => {
      if (type === 'load') {
        sendMessage({
          type: 'load',
          tagName,
          scriptType,
          attributes: iframeAttributes,
          eventNames,
          style: {
            width: containerSizes?.width,
            height: containerSizes?.height,
          },
          url: previewUrl,
        });
      } else if (type === 'message' && typeof payload === 'object') {
        const { messageType, eventName, detail } = payload;

        if (messageType === 'iframe_web_component_trigger_event') {
          handleEvents?.({ type: eventName, detail });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tagName,
      iframeAttributes,
      eventNames,
      containerSizes?.width,
      containerSizes?.height,
      previewUrl,
      scriptType,
      handleEvents,
    ],
  );

  const [iframeRef, sendMessage] = useIFrame({
    reducer: handleMessage,
  });

  React.useEffect(() => {
    sendMessage({ type: 'reload' });
  }, [
    sendMessage,
    tagName,
    iframeAttributes,
    eventNames,
    containerSizes?.width,
    containerSizes?.height,
    previewUrl,
    scriptType,
  ]);

  /**
   * This useImperativeHandle returns public method which will be used by the
   * Custom Element feature in TB for the flow, when content of the file
   * in corvid were changed.
   *
   * In a case of such changes, file will have a new content, but URL to this
   * file will stay the same. Because of this iframe will not refetch data.
   *
   * So to update CustomElementPreviewComponent, 'dsCustomElementComponent' feature in TB
   * will call "onCorvidContentUpdate" and we will reload iframe.
   *
   * Feature https://github.com/wix-private/thunderbolt/blob/master/packages/feature-wix-custom-element-component/src/ds/dsCustomElementComponent.ts
   */

  React.useImperativeHandle(ref, () => ({
    onCorvidContentUpdate: () => {
      sendMessage({ type: 'reload' });
    },
  }));

  const shouldRenderPremiumBanner =
    componentViewMode === 'editor' &&
    !isAllowedForFreeSite &&
    (!isPremiumUser || !hasDomainFeature || !isAdsFree);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        styles['custom-element-preview-container'],
        className,
      )}
      data-testid="custom-element"
    >
      {canRenderIframe && (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={customElementPreviewIframe}
          id={`${id}-iframe`}
          ref={iframeRef}
        />
      )}
      {shouldRenderPremiumBanner ? (
        <PremiumBanner
          isWixBlocks={isWixBlocks}
          isPremiumUser={isPremiumUser}
          hasDomainFeature={hasDomainFeature}
          translate={translate}
        />
      ) : null}
    </div>
  );
};

export default React.forwardRef(CustomElementPreviewComponent);
