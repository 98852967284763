import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { SearchBoxCSSVars as SearchBoxCarmiCSSVars } from '@wix/thunderbolt-components';
import { LayoutType } from '../documentManagement/SearchBox.schemaType';
import {
  ISearchBoxMapperProps,
  ISearchBoxMapperCSSVars,
  ElementDisplay,
  SearchBoxDefinition,
  SearchBoxCarmiProps,
} from '../SearchBox.types';
import { Border, extractBorderWidth } from './extractBorderWidth';

const searchButtonVisibilityMap: Record<LayoutType, ElementDisplay> = {
  icon: 'none',
  button: 'flex',
};

const searchIconVisibilityMap: Record<LayoutType, ElementDisplay> = {
  icon: 'flex',
  button: 'none',
};

export const props = withCompInfo<
  ISearchBoxMapperProps,
  SearchBoxDefinition,
  SearchBoxCarmiProps
>()(
  ['direction', 'compData', 'compProps', 'isMobileView'],
  ({ direction, compData, compProps, isMobileView }, carmiProps) => {
    const { suggestionsEnabled, placeholder } = compData;
    const { textAlignment, trendingItemsEnabled = true } = compProps;
    const useModalSuggestions = isMobileView;

    const autocompleteEnabled =
      direction !== 'rtl' &&
      (textAlignment !== 'center' ||
        (suggestionsEnabled && useModalSuggestions));

    return {
      ...carmiProps,
      autocompleteEnabled,
      direction,
      placeholder,
      suggestionsEnabled,
      trendingItemsEnabled,
      useModalSuggestions,
    };
  },
);

export const css = withCompInfo<
  ISearchBoxMapperCSSVars,
  SearchBoxDefinition,
  SearchBoxCarmiCSSVars
>()(
  ['compProps', 'direction', 'isMobileView', 'styleProperties'],
  ({ compProps, direction, isMobileView, styleProperties }, carmiCss) => {
    const { textAlignment, iconAlignment, layout, iconSpacing, textSpacing } =
      compProps;
    const isCenterTextAlignment = textAlignment === 'center';
    const textPaddingEnd = isCenterTextAlignment && isMobileView ? '4px' : '0';
    const textPadding =
      direction === 'rtl'
        ? `8px 0 8px ${textPaddingEnd}`
        : `8px ${textPaddingEnd} 8px 0`;

    const iconDirection =
      !iconAlignment || iconAlignment === 'left' ? 'row' : 'row-reverse';

    const containerDirection = layout === 'button' ? 'row' : iconDirection;

    const inputFocusBottomBorderWidth = extractBorderWidth(
      styleProperties.inputFocusBorderWidth,
      Border.Bottom,
    );

    const inputLeftBorderWidth = extractBorderWidth(
      styleProperties.inputBorderWidth,
      Border.Left,
    );
    const inputHoverLeftBorderWidth = extractBorderWidth(
      styleProperties.inputHoverBorderWidth,
      Border.Left,
    );
    const inputFocusLeftBorderWidth = extractBorderWidth(
      styleProperties.inputFocusBorderWidth,
      Border.Left,
    );

    return {
      ...carmiCss,
      '--input-text-align': isCenterTextAlignment ? textAlignment : undefined,
      '--text-padding': textPadding,
      '--container-direction': containerDirection,
      '--icon-spacing': `${iconSpacing}px`,
      '--input-focus-bottom-border-width': inputFocusBottomBorderWidth,
      '--input-left-border-width': inputLeftBorderWidth,
      '--input-hover-left-border-width': inputHoverLeftBorderWidth,
      '--input-focus-left-border-width': inputFocusLeftBorderWidth,
      '--search-button-display': searchButtonVisibilityMap[layout] || 'none',
      '--search-icon-display': searchIconVisibilityMap[layout] || 'none',
      '--text-spacing': `${textSpacing}px`,
    };
  },
);

export default createComponentMapperModel({ props, css });
