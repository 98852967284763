import * as React from 'react';
import Container from '@wix/thunderbolt-elements/dist/components/Container/viewer/skinComps/DefaultAreaSkin/DefaultAreaSkin.skin';
import { getDataAttributes } from '@wix/thunderbolt-elements/src/core/commons/utils';
import { IAccordionItemClassicProps } from '../../../types';

const AccordionItemClassic: React.FC<IAccordionItemClassicProps> = props => {
  const { id, children, meshProps } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <Container
      {...getDataAttributes(props)}
      id={id}
      hasPlatformClickHandler={false}
      meshProps={meshProps}
    >
      {childrenToRender}
    </Container>
  );
};

export default AccordionItemClassic;
