import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  VideoPlayerDefinition,
  VideoPlayerPlatformProps,
  VideoPlayerData,
} from '../VideoPlayer.types';

export const props = withCompInfo<
  VideoPlayerPlatformProps,
  VideoPlayerDefinition
>()(
  ['compData', 'compProps', 'isMobileView', 'compLayout'],
  ({ compData, compProps, isMobileView, compLayout }) => {
    return {
      src: getVideoUrl(compData) || '',
      loop: compProps.loop,
      controls: compProps.controlsVisibility === 'hover',
      showTitle: compProps.showVideoTitle,
      playableConfig: {
        title: compData.videoTitle || '',
        description: compData.description || '',
        poster: compData.videoRef?.posterImageRef,
      },
      muted: compProps.autoplay,
      autoplay: compProps.autoplay,
      isMobileView,
      containerDimensions: {
        width: `${compLayout.width}px`,
        height: `${compLayout.height}px`,
      },

      currentTime: 0,
      duration: compData.videoRef?.duration || 0,
      isPlaying: false,
      volume: compProps.autoplay ? 0 : 100,
      isMuted: compProps.autoplay,
    };
  },
);

const getVideoUrl = (compData: VideoPlayerData) => {
  const { videoUrl } = compData;
  if (videoUrl) {
    return videoUrl;
  }

  const qualities = compData.videoRef?.qualities;

  if (!qualities || !qualities.length) {
    return;
  }

  type Quality = typeof qualities[0];

  const { url } = qualities.reduce((acc: Quality, next: Quality) =>
    acc.width! > next.width! ? acc : next,
  );

  return `https://video.wixstatic.com/${url}`; // TODO staticVideoUrl
};

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
