import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { SocialAuthDefinition, SocialAuthProps } from '../SocialAuth.types';
import { props as viewerPropsMapper } from './SocialAuth.mapper';

const props = composeMapper<
  PreviewWrapperProps<SocialAuthProps>,
  SocialAuthDefinition
>()(viewerPropsMapper, {}, (resolver, dependencies, carmiData) => {
  const viewerProps = resolver(dependencies, carmiData);

  return {
    ...viewerProps,
    previewWrapperProps: {
      blockingLayer: {
        enabled: true,
        tooltipText: 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE',
      },
    },
  };
});

export default createComponentPreviewMapperModel({
  props,
});
