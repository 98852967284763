import { withCompInfo } from '@wix/editor-elements-integrations';
import type {
  MapperArgs,
  BaseDataItem,
} from '@wix/editor-elements-types/thunderbolt';
import {
  TpaGalleryDefinition,
  TpaGalleryProps,
  TpaGalleryPropsCarmiData,
} from '@wix/thunderbolt-components-native';
import {
  TpaGalleryCSSVars,
  TPAGallerySdkData,
} from '../../../tpaGallery/types';
import { getUrl } from '../../../tpaGallery';

const santaBase = '1.13152.0';

export const propsDependencies: Array<any> = [
  'compId',
  'compProps',
  'viewMode',
  'language',
  'topology',
  'mainPageId',
  'globalImageQuality',
];

export function propsResolver<GalleryDefinition extends TpaGalleryDefinition>(
  data: MapperArgs<any, GalleryDefinition>,
  carmiData: TpaGalleryPropsCarmiData,
  filepath: string,
): TpaGalleryProps {
  const {
    compId,
    compProps,
    viewMode,
    language,
    topology,
    mainPageId,
    globalImageQuality,
  } = data;
  const { images = [] } = carmiData;
  const baseUrl = `${topology.scriptsUrl}/services/santa/${santaBase}/node_modules/@wix/santa-galleries/target/${filepath}`;

  return {
    images: images.map(image => ({
      quality: globalImageQuality,
      ...image,
    })),
    mainPageId,
    quality: globalImageQuality,
    compProps,
    url: getUrl(baseUrl, compId, viewMode, language),
  };
}

export function generateSdkData<
  GalleryDefinition extends TpaGalleryDefinition<BaseDataItem>,
>() {
  return withCompInfo<TPAGallerySdkData, GalleryDefinition>()(
    ['compData'],
    ({ compData }) => ({ dataId: compData.id }),
  );
}

export function generateCss<
  GalleryCSSVars extends TpaGalleryCSSVars,
  GalleryDefinition extends TpaGalleryDefinition<BaseDataItem>,
>() {
  return withCompInfo<GalleryCSSVars, GalleryDefinition>()(
    ['compLayout'],
    ({ compLayout }) =>
      ({ '--height': `${compLayout.height}px` } as GalleryCSSVars),
  );
}
