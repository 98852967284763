import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { IMatrixGalleryControllerActions } from '../MatrixGallery.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IMatrixGalleryControllerActions => ({
  setItemToFocusIndex: (index: number) => {
    updateProps({
      itemToFocusIndex: index,
    });
  },
  setNumberOfExtraRows: (extraRows: number) => {
    updateProps({
      numberOfExtraRows: extraRows,
    });
  },
});

export default mapActionsToProps;
