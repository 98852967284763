import type { Direction } from '@wix/editor-elements-types/thunderbolt';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getInputHeightInPx,
  getLabelPadding,
  getRequiredIndicationDisplay,
} from '../../../core/commons/inputUtils';
import {
  TextInputDefinition,
  TextInputPlatformProps,
  TextInputCSSVars,
  TextInputCssCarmiData,
  TextInputType,
} from '../TextInput.types';
import { textTypeToName } from './constants';

const getName = (
  textType: TextInputType,
  alt: string | undefined,
): string | undefined => {
  if (textTypeToName[textType]) {
    return textTypeToName[textType];
  } else if (alt) {
    return alt.toLowerCase().replace(' ', '-');
  }
  return undefined;
};

export const props = withCompInfo<
  TextInputPlatformProps,
  TextInputDefinition
>()(['compData', 'compProps', 'skin'], ({ compData, compProps, skin }) => {
  return {
    skin:
      skin === 'ResponsiveTextInputSkin'
        ? 'ResponsiveTextInputSkin'
        : 'AppsTextInputSkin',
    value: compData.value || '',
    inputType: compData.textType,
    label: compData.label,
    name: getName(
      compData.textType,
      compData.label || compData.placeholder || compProps.placeholder,
    ),
    placeholder: compData.placeholder || compProps.placeholder,
    readOnly: compProps.readOnly || false,
    required: compProps.required,
    autoComplete: compData.textType !== 'password' && compProps.autoComplete,
    autoComplete_:
      compData.textType !== 'password' && compProps.autoComplete ? 'on' : null,
    isDisabled: compProps.isDisabled || false,
    pattern: compData.pattern,
    maxLength: compData.maxLength,
    ...(compData.textType === 'number' && {
      min: compData.min,
      max: compData.max,
      step: compData.step,
    }),
    prefix: compData.prefix,
    phoneFormat: compData.phoneFormat,
  };
});

const getTextPaddingCssVar = (
  alignment: TextInputDefinition['property']['textAlignment'],
  textPadding: TextInputDefinition['property']['textPadding'],
  withPrefix: boolean,
): string | undefined => {
  const padding = textPadding === undefined ? 3 : textPadding;
  const addedPrefix = withPrefix ? 50 : 0;
  switch (alignment) {
    case 'left':
      return `3px 3px 3px ${padding + addedPrefix}px`;
    case 'right':
      return `3px ${padding}px 3px ${3 + addedPrefix}px`;
    default:
      return `3px ${3 + addedPrefix}px 3px ${3 + addedPrefix}px`;
  }
};

const getDir = (
  textAlignment: TextInputDefinition['property']['textAlignment'],
  hasPhoneFormat: boolean,
): Direction => {
  if (hasPhoneFormat) {
    return 'ltr';
  }

  return textAlignment === 'right' ? 'rtl' : 'ltr';
};

export const css = withCompInfo<
  TextInputCSSVars,
  TextInputDefinition,
  TextInputCssCarmiData
>()(
  [
    'compLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
  ],
  (
    {
      compLayout,
      compProps,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
      compData,
    },
    cssVars,
  ) => {
    const { textAlignment, textPadding, labelMargin } = compProps;
    const { prefix, phoneFormat } = compData;

    const sharedProps = {
      ...cssVars,
      '--dir': getDir(textAlignment, !!phoneFormat),
      '--textAlign': textAlignment,
      '--textPadding': getTextPaddingCssVar(
        textAlignment,
        textPadding,
        !!prefix,
      ),
      '--labelPadding': getLabelPadding(compProps),
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      '--labelMarginBottom': `${labelMargin}px`,
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeightInPx({
          compProps,
          compLayout,
          isMobileView,
        }),
      };
    }
  },
);

export default createComponentMapperModel({ props, css });
