import * as React from 'react';
import classNames from 'classnames';
import {
  CellsMapType,
  IdIndexMapType,
  supportedKeyboardKeys,
  getRepeaterCells,
  getNextCell,
} from '@wix/thunderbolt-commons/dist/repeater';
import { IResponsiveRepeaterSkinProps } from '../ResponsiveRepeater.types';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { getDataAttributes } from '../../../core/commons/utils';

const ResponsiveRepeater: React.FC<IResponsiveRepeaterSkinProps> = props => {
  const {
    id,
    responsiveContainerProps,
    keyboardNavigationEnabled,
    items,
    children,
    classes,
    className,
  } = props;

  const responsiveContainerChildren = React.useCallback(
    () =>
      items.map(itemId => (
        <React.Fragment key={itemId}>{children(itemId)}</React.Fragment>
      )),
    [children, items],
  );

  const repeaterRef = React.useRef<HTMLDivElement>(null);
  const [rootWidth, setRootWidth] = React.useState<number>(0);
  const [cellsRefs, setCellsRefs] = React.useState<CellsMapType>([]);
  const [indexMap, setIndexMap] = React.useState<IdIndexMapType>({});

  React.useLayoutEffect(() => {
    if (repeaterRef.current && keyboardNavigationEnabled) {
      setRootWidth(repeaterRef.current.offsetWidth);
      const { cellsMap, idIndexMap } = getRepeaterCells(
        items,
        repeaterRef.current,
      );
      cellsMap.forEach((row, rowIndex) =>
        row.forEach((cell, columnIndex) => {
          cell.setAttribute(
            'tabindex',
            rowIndex === 0 && columnIndex === 0 ? '0' : '-1',
          );
        }),
      );
      setCellsRefs(cellsMap);
      setIndexMap(idIndexMap);
    }
  }, [keyboardNavigationEnabled, items]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!keyboardNavigationEnabled || !repeaterRef.current) {
      return;
    }

    const target = event.target as Element;
    let cellsMap = cellsRefs;
    let idIndexMap = indexMap;

    const shouldHandleKeyboardEvent =
      supportedKeyboardKeys.includes(event.key) &&
      target.tagName !== 'INPUT' &&
      target.tagName !== 'SELECT';

    if (!shouldHandleKeyboardEvent || !idIndexMap[target.id]) {
      return;
    }

    event.preventDefault();

    if (repeaterRef.current.offsetWidth !== rootWidth) {
      const repeaterCells = getRepeaterCells(items, repeaterRef.current);
      cellsMap = repeaterCells.cellsMap;
      idIndexMap = repeaterCells.idIndexMap;
      setCellsRefs(repeaterCells.cellsMap);
      setIndexMap(repeaterCells.idIndexMap);
      setRootWidth(repeaterRef.current.offsetWidth);
    }

    const cellRef: HTMLElement | undefined = getNextCell(
      cellsMap,
      idIndexMap,
      target.id,
      event.key,
    );

    cellRef?.focus();
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, classes.bg)}
      ref={repeaterRef}
      onKeyDown={handleKeyDown}
    >
      <ResponsiveContainer {...responsiveContainerProps}>
        {responsiveContainerChildren}
      </ResponsiveContainer>
    </div>
  );
};

export default ResponsiveRepeater;
