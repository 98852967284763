import * as React from 'react';
import Card from 'wix-style-react/dist/src/Card';
import { createSlotsContainer } from '@wix/editor-elements-integrations/dist/thunderbolt/createSlotsContainer';

import { getDataAttributes } from '@wix/thunderbolt-elements/src/core/commons/utils';
import type { ISimpleCardProps } from '../SimpleCard.props';

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const SimpleCard: React.FC<ISimpleCardProps> = props => {
  const { id, slot, title, className } = props;

  return (
    <div className={className} {...getDataAttributes(props)} id={id}>
      <Card stretchVertically>
        <Card.Header title={title} />
        <Card.Divider />
        <Card.Content>{slot}</Card.Content>
      </Card>
    </div>
  );
};

export default createSlotsContainer(SimpleCard);
