import * as React from 'react';

import { EditorProps } from 'draft-js';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  IRichTextBoxImperativeActions,
  IRichTextBoxProps,
} from '../RichTextBox.types';

import { getDataAttributes } from '../../../core/commons/utils';
import getToolbarByPosition from './toolbars/toolbarByPosition';
import Editor from './editor/Editor';
import editorStyles from './editor/editorStyles.scss';

import StylesContext from './StylesContext';

const RichTextBox: React.ForwardRefRenderFunction<
  IRichTextBoxImperativeActions,
  IRichTextBoxProps
> = (props, ref) => {
  const {
    id,
    className,
    label,
    placeholder,
    textAlignment,
    componentViewMode,
    readOnly,
    required,
    isDisabled,
    value,
    maxLength,
    allowLinks,
    isValid,
    shouldShowValidityIndication,
    validateValueAndShowIndication = () => {},
    hideValidityIndication = () => {},
    onBlur = () => {},
    onChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onFocus = () => {},
    onValueChange = () => {},
    onKeyPress = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    openMobileLinkModal,
  } = props;
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const { toolbarPosition = 'top' } = props;
  const toolbar = useMemo(
    () => getToolbarByPosition(toolbarPosition),
    [toolbarPosition],
  );

  const _onMouseEnter = (e: React.MouseEvent) => {
    setHovered(true);
    onMouseEnter(e);
  };
  const _onMouseLeave = (e: React.MouseEvent) => {
    setHovered(false);
    onMouseLeave(e);
  };

  const _onFocus = (e: React.FocusEvent) => {
    setFocused(true);
    onFocus(e);
  };
  const _onBlur = (e: React.FocusEvent) => {
    setFocused(false);
    onBlur(e);
  };

  const hasError = shouldShowValidityIndication && !isValid;
  return (
    <StylesContext.Provider
      value={{
        hovered,
        focused,
        required,
        error: hasError,
        disabled: Boolean(isDisabled),
      }}
    >
      <div
        id={id}
        {...getDataAttributes(props)}
        onClick={onClick}
        onDoubleClick={onDblClick}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
        onFocus={_onFocus}
        onBlur={_onBlur}
        onKeyPress={onKeyPress}
        className={classNames(className, {
          [editorStyles.containerWithLabel]: !!label,
        })}
      >
        <Editor
          ref={ref}
          hideValidityIndication={hideValidityIndication}
          validateValueAndShowIndication={validateValueAndShowIndication}
          onChangeState={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onValueChange={onValueChange}
          placeholder={placeholder}
          readOnly={readOnly || isDisabled}
          value={value}
          maxLength={maxLength}
          toolbar={toolbar}
          textAlignment={textAlignment as EditorProps['textAlignment']}
          componentViewMode={componentViewMode}
          allowLinks={allowLinks}
          openMobileLinkModal={openMobileLinkModal}
          rootId={id}
          label={label}
        />
      </div>
    </StylesContext.Provider>
  );
};

export default React.forwardRef(RichTextBox);
