import * as React from 'react';
import type {
  IDropDownMenuBaseProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';

const SeparateShinyIIMenuButton: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuBaseProps
> = (props, ref) => {
  const { styles, Button } = props;
  return (
    <DropDownMenuBase {...props} ref={ref} styles={styles} Button={Button} />
  );
};

export default React.forwardRef(SeparateShinyIIMenuButton);
