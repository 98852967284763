import React, { useEffect } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { usePrevious } from '@wix/editor-elements-preview-utils';
import { MatrixGallerySkinProps } from '../MatrixGallery.types';

export default (ViewerComponent: React.ComponentType<MatrixGallerySkinProps>) =>
  createComponentPreviewEntry(
    ({
      previewWrapperProps: {
        shouldResetGalleryToOriginalState: shouldReset,
      } = {},
      ...viewerProps
    }: PreviewWrapperProps<
      MatrixGallerySkinProps,
      {
        shouldResetGalleryToOriginalState?: boolean;
      }
    >) => {
      const { setNumberOfExtraRows, setItemToFocusIndex } = viewerProps;

      const prevShouldReset = usePrevious(shouldReset);
      useEffect(() => {
        if (shouldReset !== prevShouldReset) {
          setNumberOfExtraRows(0);
          setItemToFocusIndex(-1);
        }
      }, [
        shouldReset,
        prevShouldReset,
        setNumberOfExtraRows,
        setItemToFocusIndex,
      ]);

      return <ViewerComponent {...viewerProps} />;
    },
  );
