import {
  createComponentPreviewMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  StylableHorizontalMenuProps,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData,
  StylableHorizontalMenuCSSVars,
} from '../StylableHorizontalMenu.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './StylableHorizontalMenu.mapper';

function getMenuMode({
  menuMode,
  metaSiteId,
}: {
  menuMode: string;
  metaSiteId: string;
}) {
  const isSHMInAddPanel = !metaSiteId;
  return isSHMInAddPanel ? 'scroll' : menuMode;
}

const props = composeMapper<
  PreviewWrapperProps<StylableHorizontalMenuProps>,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'compLayout', 'metaSiteId'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState, compLayout } = dependencies;

    return {
      ...viewerProps,
      menuMode: getMenuMode({
        menuMode: viewerProps.menuMode,
        metaSiteId: dependencies.metaSiteId,
      }),
      previewWrapperProps: {
        compPreviewState,
        compLayout,
      },
    };
  },
);

export const css = composeMapper<
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuDefinition
>()(
  viewerCssMapper,
  { add: ['compProps', 'metaSiteId', 'componentViewMode'] },
  (resolver, dependencies, carmiData): StylableHorizontalMenuCSSVars => {
    const viewerCss = resolver(dependencies, carmiData);
    const { metaSiteId, componentViewMode } = dependencies;

    const isMiniSite = !metaSiteId;
    if (isMiniSite) {
      // wrap mode looks bad in a mini site (add panel / copy design)
      return {
        ...viewerCss,
        '--menu-flex-wrap': 'nowrap',
        '--scroll-controls-display': 'none',
      };
    }

    return {
      ...viewerCss,
      '--is-animated': componentViewMode === 'editor' ? 0 : 1, // no animation for the editor
    };
  },
);

const uiType = withCompInfo()([], (() => 'Default') as any);

export default createComponentPreviewMapperModel({
  props,
  css,
  uiType,
} as any);
