import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  DropDownMenuDefinition,
  DropDownMenuPlatformProps,
  DropDownMenuCarmiData,
} from '../DropDownMenu.types';
import { props as viewerMapperProps } from './DropDownMenu.mapper';

const props = composeMapper<
  PreviewWrapperProps<DropDownMenuPlatformProps>,
  DropDownMenuDefinition,
  DropDownMenuCarmiData
>()(
  viewerMapperProps,
  { add: ['compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
