import * as React from 'react';
import WrapperElement from '../../WrapperElement';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import skinStyles from './styles/skins.scss';

const LiftedBottomScreen: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps,
  children,
}) => {
  return (
    <WrapperElement
      {...wrapperProps}
      skinClassName={skinStyles.LiftedBottomScreen}
      skinStyles={skinStyles}
    >
      <div className={skinStyles.screenWidthBackground}></div>
      <div className={skinStyles.centeredContent}>
        <div className={skinStyles.centeredContentBg}>
          <div
            className={`${skinStyles.shadow} ${skinStyles.leftBottom}`}
          ></div>
          <div
            className={`${skinStyles.shadow} ${skinStyles.centerBottom}`}
          ></div>
          <div
            className={`${skinStyles.shadow} ${skinStyles.rightBottom}`}
          ></div>
          <div className={skinStyles.border}>
            <div className={skinStyles.bg}></div>
          </div>
        </div>
        <div className={skinStyles.inlineContent}>{children}</div>
      </div>
    </WrapperElement>
  );
};

export default LiftedBottomScreen;
