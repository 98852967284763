import { withComponentController } from '@wix/editor-elements-integrations';
import {
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
  DisqusCommentsControllerProps,
} from '../DisqusComments.types';

const compController = withComponentController<
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
  DisqusCommentsControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
  };
});

export default compController;
