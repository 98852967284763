import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { ITabsControllerActions } from '../Tabs.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ITabsControllerActions => ({
  setCurrentTabId: currentTabId => {
    updateProps({ currentTabId });
  },
});

export default mapActionsToProps;
