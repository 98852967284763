import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';
import React from 'react';
import {
  IPopupContainerProps,
  PopupContainerPreviewProps,
} from '../PopupContainer.types';
import { withComponentPreview as withMediaContainerPreviewWrapper } from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewWrapper';

export const HOME_PAGE_ID = 'c1dmp';
export const WIX_PAGE_SELECTOR = `#${HOME_PAGE_ID}`;

function withComponentPreview(
  ViewerComponent: React.ComponentType<IPopupContainerProps>,
): IComponentPreviewWrapper<IPopupContainerProps, PopupContainerPreviewProps> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    PreviewWrapperProps<IPopupContainerProps, PopupContainerPreviewProps>
  >((props, ref) => {
    const { previewWrapperProps = {}, ...viewerProps } = props;
    const { componentViewMode, isMobileView, isFullWidthPopup } =
      previewWrapperProps;
    const previousViewMode = usePrevious(componentViewMode);
    const key = useResetComponent({
      shouldResetComponent:
        !!previousViewMode && previousViewMode !== componentViewMode,
      id: viewerProps.id,
    });

    React.useLayoutEffect(() => {
      const wrapper = document.getElementById('popups-wrapper');
      if (wrapper) {
        wrapper.style.width = isMobileView ? 'var(--site-width)' : '';
      }
    }, [isMobileView]);

    React.useImperativeHandle(
      ref,
      () => ({
        getCustomMeasures: () => ({
          relativeToScreenOverrides: {
            [props.id]: () => {
              const container = document.getElementById(props.id);
              if (container) {
                const { x, y } = container.getBoundingClientRect();
                return {
                  // Full width popups inherit position from viewer frame
                  x: isFullWidthPopup ? 0 : x,
                  y,
                };
              }
              return;
            },
          },
          left: {
            [props.id]: () => {
              if (isMobileView) {
                const wixPage = document.querySelector(WIX_PAGE_SELECTOR);
                const container = document.getElementById(props.id);
                if (isFullWidthPopup || !container || !wixPage) {
                  return 0;
                }

                const { left: wixPageLeft } = wixPage.getBoundingClientRect();
                const { left } = container.getBoundingClientRect();

                // Margin is calculated from start of viewer frame instead of whole page
                return left - wixPageLeft;
              } else if (isFullWidthPopup) {
                return 0;
              }
              return;
            },
          },
        }),
      }),
      [props.id, isFullWidthPopup, isMobileView],
    );

    return <ViewerComponent key={key} {...viewerProps} />;
  });
}

export default (ViewerComponent: React.ComponentType<IPopupContainerProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withMediaContainerPreviewWrapper(ViewerComponent)),
  );
