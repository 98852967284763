import * as React from 'react';
import classNames from 'classnames';
import { useAuthIframeSubscription } from '../../MemberLoginDialog/viewer/useAuthIframeSubscription';
import { ISocialAuthProps } from '../SocialAuth.types';
import { SocialIcons } from '../../MemberLoginDialog/viewer/SocialAuth';
import {
  IIFrameConfig,
  ISocialVendors,
} from '../../MemberLoginDialog/MemberLoginDialog.types';
import { useSocialErrorHandler } from '../../MemberLoginDialog/useSocialErrorHandler';
import { UnsupportedSocialAuthMessage } from '../../MemberLoginDialog/viewer/UnsupportedSocialAuthMessage';
import { getReportCopyToClipboardBiCallback } from '../../MemberLoginDialog/viewer/utils';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/style.scss';
import { translationFeature } from './constants';

const SocialAuth: React.FC<ISocialAuthProps> = props => {
  const {
    id,
    className,
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked,
    isSocialLoginGoogleEnabled,
    isSocialLoginFacebookEnabled,
    mode,
    isSmallIcons,
    formType = 'corvid',
    onTokenMessage,
    onBackendSocialLogin,
    reportBi,
    getHostReadyPayload,
    isSocialAuthSupported = true,
  } = props;

  const translate = props.translate!.bind(null, translationFeature);

  const [iframeReady, setIframeReady] = React.useState(false);
  const [
    socialAuthError,
    translateAndSetSocialAuthError,
    resetSocialAuthError,
  ] = useSocialErrorHandler(translate);

  const iframeRef = useAuthIframeSubscription(
    resetSocialAuthError(onTokenMessage),
    resetSocialAuthError(onBackendSocialLogin),
    reportBi,
    'fullscreen',
    formType,
    isCommunityChecked,
    () => setIframeReady(true),
    translateAndSetSocialAuthError,
    getHostReadyPayload,
  );

  const iframeConfig: IIFrameConfig = {
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked,
    useGoogleSdk: props.useGoogleSdk,
  };

  const enabledSocialVendors = new Set<ISocialVendors>();
  if (isSocialLoginGoogleEnabled) {
    enabledSocialVendors.add('google');
  }
  if (isSocialLoginFacebookEnabled) {
    enabledSocialVendors.add('facebook');
  }

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.socialLoginWrapper)}
    >
      {isSocialAuthSupported ? (
        <SocialIcons
          id={id}
          enabledSocialVendors={enabledSocialVendors}
          iframeConfig={iframeConfig}
          style={style}
          mode={mode}
          translate={translate}
          isHorizontal={isSmallIcons}
          ready={iframeReady}
          error={socialAuthError}
          ref={iframeRef}
        />
      ) : (
        <UnsupportedSocialAuthMessage
          isSocialLoginGoogleEnabled={isSocialLoginGoogleEnabled}
          isSocialLoginFacebookEnabled={isSocialLoginFacebookEnabled}
          translate={translate}
          mode="login"
          styleMode="custom"
          reportCopyToClipboardBi={getReportCopyToClipboardBiCallback(
            reportBi,
            { biVisitorId, metaSiteId },
          )}
        />
      )}
    </div>
  );
};

export default SocialAuth;
