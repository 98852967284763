import { withComponentController } from '@wix/editor-elements-integrations';
import {
  LineShareButtonMapperProps,
  LineShareStateRefs,
  LineShareButtonControllerProps,
} from '../LineShareButton.types';

const compController = withComponentController<
  LineShareButtonMapperProps,
  LineShareStateRefs,
  LineShareButtonControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
  };
});

export default compController;
