import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { CssBorder } from '@wix/thunderbolt-components-native';
import {
  MediaContainerCompProps,
  MediaContainerVideoAPI,
} from '../MediaContainer.types';
import { useCustomMeasuresDependency } from '../../../../preview-utils/useCustomMeasuresDependency';
import { useOnVideoStopCallback } from '../../../../preview-utils/useOnVideoStopCallback';
import { useVideoPlayEffect } from '../../../../preview-utils/useVideoPlayEffect';

export function withComponentPreview<
  T extends Record<string, any> = MediaContainerCompProps,
>(ViewerComponent: React.ComponentType<T>) {
  return React.forwardRef<MediaContainerVideoAPI, T>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        T,
        {
          autoPlayVideo?: boolean;
          isPlayingAllowed?: boolean;
          borderWidth?: CssBorder['width'];
        }
      >,
      ref,
    ) => {
      const { autoPlayVideo, isPlayingAllowed, borderWidth } =
        previewWrapperProps;
      const compRef = React.useRef<MediaContainerVideoAPI | null>(null);

      const borderWidthRef = useCustomMeasuresDependency(borderWidth);

      React.useImperativeHandle<MediaContainerVideoAPI | null, any>(
        ref,
        () => ({
          getCustomMeasures: () => {
            return {
              width: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderLeftWidth =
                    borderWidthRef.current?.left.value || 0;
                  const borderRightWidth =
                    borderWidthRef.current?.right.value || 0;
                  const width = mediaContainer.offsetWidth;
                  return width - borderLeftWidth - borderRightWidth;
                },
              },
              height: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderTopWidth = borderWidthRef.current?.top.value || 0;
                  const borderBottomWidth =
                    borderWidthRef.current?.bottom.value || 0;
                  const height = mediaContainer.offsetHeight;
                  return height - borderTopWidth - borderBottomWidth;
                },
              },
              topOffset: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderTopWidth = borderWidthRef.current?.top.value ?? 0;
                  return borderTopWidth;
                },
              },
              leftOffset: {
                [props.id]: () => {
                  const mediaContainer = document.getElementById(props.id);
                  if (!mediaContainer) {
                    return;
                  }
                  const borderLeftWidth =
                    borderWidthRef.current?.left.value ?? 0;
                  return borderLeftWidth;
                },
              },
            };
          },
          ...(compRef && compRef.current),
        }),
      );

      useVideoPlayEffect({ compRef, isPlayingAllowed, autoPlayVideo });

      const onStop = useOnVideoStopCallback();

      const props = {
        ...viewerProps,
        ref: compRef,
        onStop: isPlayingAllowed ? undefined : onStop,
      } as unknown as T;

      return <ViewerComponent {...props} />;
    },
  );
}

export default (
  ViewerComponent: React.ComponentType<MediaContainerCompProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
