import * as React from 'react';
import { useCallback, useState } from 'react';
import {
  IAddressInputImperativeActions,
  IAddressInputProps,
  Suggestion,
} from '../AddressInput.types';
import { useClickOutside } from '../../../providers/useClickOutside/useClickOutside';
import { keyCodes } from '../../../core/commons/a11y';
import { usePopper } from '../../../providers/usePopper/usePopper';
import { ReactComponent as AddressIcon } from '../assets/AddressIcon.svg';
import { getDataAttributes, throttle2 } from '../../../core/commons/utils';
import { useAtlasHandler } from '../../../providers/useAtlasHandler';
import { st, classes } from './style/AddressInput.st.css';
import { testIds, ariaNamespace } from './constants';
import biEvents from './bi/events';

const noop = () => {};
const resolveToEmptyObject = () => Promise.resolve({});

const AddressInput: React.ForwardRefRenderFunction<
  IAddressInputImperativeActions,
  IAddressInputProps
> = (props, ref) => {
  const {
    value,
    placeholder,
    id,
    country,
    isDisabled,
    readOnly,
    dividerVisible,
    iconVisible,
    alignment,
    translate,
    onChange = noop,
    onValueChange = noop,
    onFocus = noop,
    onBlur = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    validateValueAndShowIndication = noop,
    getPlace = resolveToEmptyObject,
    predict = resolveToEmptyObject,
    shouldShowValidityIndication,
    isValid,
    label,
    required,
    suggestions = [],
    suggestionsVisibility = false,
    onSuggestionsUpdate,
    onSuggestionsVisibilityUpdate,
    className,
  } = props;

  const {
    ref: inputRef,
    setRef: setInputRef,
    popper,
    setPopper,
    styles,
    attributes,
  } = usePopper<HTMLInputElement>({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          boundary: 'clippingParents',
          fallbackPlacements: ['top-start', 'bottom-start'],
          allowedAutoPlacements: ['top-start', 'bottom-start'],
        },
      },
    ],
  });

  const [inputValue, setInputValue] = useState<string>(value?.formatted || '');

  const [hoveredOptionIndex, setHoveredOptionIndex] = useState(-1);
  const translateFn = useCallback(
    (key: string, fallbackValue: string) => {
      return translate(ariaNamespace, key, fallbackValue);
    },
    [translate],
  );

  const { autoComplete, getGeocode } = useAtlasHandler({
    country,
    getPlace,
    predict,
    translate: translateFn,
    onSuggestionsUpdate,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledAutoComplete = useCallback(throttle2(autoComplete, 150), [
    autoComplete,
  ]);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef?.focus();
      },
      blur: () => {
        inputRef?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef?.setCustomValidity(message.message);
        }
      },
      getValidationMessage: () => {
        return inputRef?.validationMessage;
      },
    };
  });

  React.useEffect(() => {
    setInputValue(value?.formatted || '');
  }, [value]);

  React.useEffect(() => {
    setHoveredOptionIndex(-1);
  }, [suggestions]);

  const shouldDropdownVisible = suggestionsVisibility && suggestions.length > 0;
  const isRightAligned = alignment === 'right';
  const isCenterAligned = alignment === 'center';

  const _hideSuggestions = () => {
    onSuggestionsVisibilityUpdate(false);
  };

  const _showSuggestions = () => {
    onSuggestionsVisibilityUpdate(true);
  };

  const _selectNextSuggestion = () => {
    let resultIndex = -1;
    if (suggestions.length > 0) {
      resultIndex =
        suggestions.length - 1 > hoveredOptionIndex
          ? hoveredOptionIndex + 1
          : 0;
    }
    setHoveredOptionIndex(resultIndex);
  };

  const _selectPrevSuggestion = () => {
    let resultIndex = -1;
    if (suggestions.length > 0) {
      resultIndex =
        hoveredOptionIndex > 0
          ? hoveredOptionIndex - 1
          : suggestions.length - 1;
    }
    setHoveredOptionIndex(resultIndex);
  };

  useClickOutside([inputRef, popper], () => {
    _hideSuggestions();
  });

  const _handleOptionMouseEnter = (index: number) => {
    setHoveredOptionIndex(index);
  };

  const _handleOptionMouseLeave = () => {
    setHoveredOptionIndex(-1);
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    const shouldChangeValue = inputValue === '' || hoveredOptionIndex === -1;
    if (shouldChangeValue) {
      onValueChange({ formatted: inputValue });
    }
    validateValueAndShowIndication();
    onBlur(event);
    if (shouldChangeValue) {
      onChange({
        ...event,
        type: 'change',
      });
    }
  };

  const _onFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    _showSuggestions();
    onFocus(event);
  };

  const _handleKeyDown: React.KeyboardEventHandler = async event => {
    if (shouldDropdownVisible && event.keyCode === keyCodes.escape) {
      event.stopPropagation();
    }
    switch (event.keyCode) {
      case keyCodes.escape:
      case keyCodes.tab:
        _hideSuggestions();
        break;
      case keyCodes.arrowUp:
        _selectPrevSuggestion();
        break;
      case keyCodes.arrowDown:
        _selectNextSuggestion();
        break;
      case keyCodes.enter:
        await _handleSuggestionClick(suggestions[hoveredOptionIndex]);
        break;
      default:
        break;
    }
  };

  const _handleSuggestionClick = async (suggestion: Suggestion) => {
    if (suggestion && suggestion.id && inputValue) {
      const result = await getGeocode({
        placeId: suggestion.id,
        rawInputValue: inputValue,
      });

      if (result && result.formatted) {
        setInputValue(result.formatted);
        onValueChange(result);
        validateValueAndShowIndication();
        onChange({
          type: 'change',
        } as any);
        _hideSuggestions();
      }
    }

    props.onBI(biEvents.addressSelect, { endpoint: 'form-builder' });
  };

  const _handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const targetInputValue = e.target.value;
    setInputValue(targetInputValue);
    validateValueAndShowIndication({
      value: { formatted: targetInputValue },
    });
    if (targetInputValue === '') {
      onSuggestionsUpdate([]);
      return;
    }
    _showSuggestions();
    throttledAutoComplete(targetInputValue);
  };

  const _renderSuggestions = () => {
    if (shouldDropdownVisible) {
      return (
        <div role="listbox" className={st(classes.dropdown)}>
          {suggestions.map((suggestion, idx) => (
            <div
              key={idx}
              id={`${id}-content_option-${hoveredOptionIndex}`}
              className={st(classes.option, {
                rightAligned: isRightAligned,
                centerAligned: isCenterAligned,
                withDivider: dividerVisible,
                hovered: hoveredOptionIndex === idx,
              })}
              aria-disabled={isDisabled}
              tabIndex={0}
              onClick={() => _handleSuggestionClick(suggestion)}
              onMouseEnter={() => _handleOptionMouseEnter(idx)}
              onMouseLeave={() => _handleOptionMouseLeave()}
              aria-selected={hoveredOptionIndex === idx}
              data-testid={testIds.suggestions}
            >
              {iconVisible && (
                <div
                  className={st(classes.iconWrapper, {
                    rightAligned: isRightAligned,
                    centerAligned: isCenterAligned,
                  })}
                >
                  <AddressIcon />
                </div>
              )}
              <span
                className={st(classes.optionText, {
                  rightAligned: isRightAligned,
                  centerAligned: isCenterAligned,
                })}
              >
                {suggestion.value}
              </span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={st(
        classes.wrapper,
        {
          required: required && !!label,
        },
        className,
      )}
      {...(!isDisabled && {
        onClick,
        onDoubleClick: onDblClick,
        onMouseEnter,
        onMouseLeave,
      })}
    >
      <div className={st(classes.root)}>
        {label && (
          <label
            data-testid={testIds.label}
            className={classes.label}
            htmlFor={`input_${id}`}
          >
            {label}
          </label>
        )}
        <input
          id={`input_${id}`}
          ref={setInputRef}
          className={`${st(classes.input, {
            disabled: isDisabled,
            withDropdown: shouldDropdownVisible,
            rightAligned: isRightAligned,
            centerAligned: isCenterAligned,
            error: !!shouldShowValidityIndication && !isValid,
          })} ignore-focus`}
          readOnly={readOnly}
          required={required}
          placeholder={placeholder}
          value={inputValue}
          disabled={isDisabled}
          role="combobox"
          autoComplete="off"
          type="search"
          aria-autocomplete="both"
          aria-owns={`${id}-content`}
          aria-controls={`${id}-content`}
          aria-expanded={shouldDropdownVisible}
          aria-activedescendant={`${id}-content_option-${hoveredOptionIndex}`}
          onChange={_handleInputChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onKeyDown={_handleKeyDown}
          data-testid={testIds.input}
        />
        <div
          id={`${id}-content`}
          ref={setPopper}
          style={{ ...styles.popper, width: '100%', zIndex: 47 }}
          {...attributes.popper}
        >
          {_renderSuggestions()}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(AddressInput);
