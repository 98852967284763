
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "AccordionContainer1266025101",
      {"classes":{"root":"AccordionContainer1266025101__root","accordion":"AccordionContainer1266025101__accordion","accordionIcon":"AccordionContainer1266025101__accordionIcon","accordionTitle":"AccordionContainer1266025101__accordionTitle","accordionContent":"AccordionContainer1266025101__accordionContent"},"vars":{},"stVars":{"title-height":"var(--title-height)","content-height":"var(--content-height)","animation-time":"var(--animation-time)","icon-animation-time":"var(--icon-animation-time)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  