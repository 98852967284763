import { useEffect, useRef, useState } from 'react';
import type { HeightCssVars } from '../../types';

type Heights = Array<number> | undefined;

const heightsToCssVars = ([
  titleHeight,
  contentHeight,
]: Heights = []): HeightCssVars => ({
  '--title-height': titleHeight,
  '--content-height': contentHeight,
});

/* measure height of elements for proper css transition */
export const useHeightCssVars = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [heights, setHeights] = useState<Heights>();

  useEffect(() => {
    const children = Array.from(ref.current!.children) as Array<HTMLElement>;
    const updateHeights = () => setHeights(children.map(el => el.offsetHeight));

    const observer = new ResizeObserver(updateHeights);
    children.forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return {
    ref,
    heightCssVars: heightsToCssVars(heights),
  };
};
