import { withComponentController } from '@wix/editor-elements-integrations';
import {
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
  WTwitterTweetControllerProps,
} from '../WTwitterTweet.types';

const compController = withComponentController<
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
  WTwitterTweetControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrl,
  };
});

export default compController;
