import { withComponentController } from '@wix/editor-elements-integrations';
import {
  VKShareButtonMapperProps,
  VKShareButtonStateRefs,
  VKShareButtonControllerProps,
} from '../VKShareButton.types';

const compController = withComponentController<
  VKShareButtonMapperProps,
  VKShareButtonStateRefs,
  VKShareButtonControllerProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
  };
});

export default compController;
