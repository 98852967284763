import React from 'react';
import type { AccordionLayoutProps } from '../../types';
import { st, classes } from '../AccordionContainer.component.st.css';
import { AccordionIcon } from './AccordionIcon';

export const AccordionLayout: React.FC<AccordionLayoutProps> = ({
  item: { id, label, content },
  isOpened,
  toggleItem,
  animationProps: {
    isContentShown = isOpened,
    ref,
    heightCssVars,
    onTransitionEnd,
  } = {},
}) => (
  <div
    className={st(classes.accordion, { isOpened })}
    ref={ref}
    onTransitionEnd={onTransitionEnd}
    style={heightCssVars as any}
  >
    <button
      className={classes.accordionTitle}
      onClick={() => toggleItem(id)}
      type="button"
    >
      <span>{label}</span>
      <span className={classes.accordionIcon}>
        <AccordionIcon />
      </span>
    </button>
    <div className={classes.accordionContent}>{isContentShown && content}</div>
  </div>
);
