import * as React from 'react';
import classNames from 'classnames';

import { IYouTubeSubscribeButtonProps } from '../YouTubeSubscribeButton.types';
import { getDataAttributes } from '../../../core/commons/utils';
import styles from './styles/YouTubeSubscribeButton.scss';
import baseIframeUrl from '!!url-loader?limit=1!./assets/iframe.html';

const YouTubeSubscribeButton: React.FC<IYouTubeSubscribeButtonProps> =
  props => {
    const {
      id,
      className,
      channel,
      layout,
      theme,
      onMouseEnter,
      onMouseLeave,
    } = props;
    const [iframeExpanded, setIframeExpanded] = React.useState(false);

    const iframeSrc = React.useMemo(() => {
      const params = { channel, layout, theme };
      return `${baseIframeUrl}?${new URLSearchParams(params)}`;
    }, [channel, layout, theme]);

    const expandIframe = () => setIframeExpanded(true);
    const collapseIframe = () => setIframeExpanded(false);
    const handleMouseEnter: React.MouseEventHandler = event => {
      expandIframe();
      onMouseEnter?.(event);
    };
    const handleMouseLeave: React.MouseEventHandler = event => {
      collapseIframe();
      onMouseLeave?.(event);
    };

    return (
      <div
        id={id}
        {...getDataAttributes(props)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
      >
        <wix-iframe
          data-src={iframeSrc}
          class={classNames(styles.iframe, {
            [styles.expanded]: iframeExpanded,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            data-src={iframeSrc}
            scrolling="no"
            frameBorder="0"
            allowFullScreen
          />
          <div
            className={styles.hoverOutVertical}
            onMouseEnter={collapseIframe}
          />
          <div
            className={styles.hoverOutHorizontal}
            onMouseEnter={collapseIframe}
          />
        </wix-iframe>
      </div>
    );
  };

export default YouTubeSubscribeButton;
