import * as React from 'react';
import classNames from 'classnames';
import { IPopupCloseIconButtonProps } from '../PopupCloseIconButton.types';
import { TestIds } from '../constants';
import {
  activateBySpaceOrEnterButton,
  getAriaAttributes,
} from '../../../core/commons/a11y';
import { replaceCompIdPlaceholder } from '../../../core/commons/vectorImageUtils';
import { getDataAttributes } from '../../../core/commons/utils';
import style from './style/PopupCloseIconButton.scss';

const PopupCloseIconButton: React.FC<IPopupCloseIconButtonProps> = props => {
  const {
    id,
    className,
    svgContent,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onDblClick,
    ariaAttributes,
    translate,
  } = props;
  const a11yLabel = translate!(
    'ariaLabels',
    'PopupCloseIconButton_AriaLabel',
    'Back to site',
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.svgRoot)}
      onKeyDown={activateBySpaceOrEnterButton}
      data-testid={TestIds.PopupCloseIconButtonRoot}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onDoubleClick={onDblClick}
    >
      <div
        tabIndex={0}
        role="button"
        title={a11yLabel}
        aria-label={a11yLabel}
        {...getAriaAttributes(ariaAttributes)}
        className={style.svgButton}
        dangerouslySetInnerHTML={{
          __html: svgContent && replaceCompIdPlaceholder(svgContent, id),
        }}
      />
    </div>
  );
};

export default PopupCloseIconButton;
