import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { IPaginationControllerActions } from '../Pagination.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IPaginationControllerActions => ({
  onChange: ({
    target: {
      value: { currentPage },
    },
  }) => updateProps({ currentPage }),
});

export default mapActionsToProps;
