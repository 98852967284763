import { FreemiumBannerResponsiveProps as FreemiumBannerResponsiveTBProps } from '@wix/thunderbolt-components';
import * as React from 'react';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import classNames from 'classnames';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import { EditorXLogo } from './assets/editorXLogo';
import * as translations from './constants';
import style from './style/FreemiumBannerResponsive.scss';

export type FreemiumBannerResponsiveProps = DefaultCompPlatformProps &
  FreemiumBannerResponsiveTBProps & { className?: string };

const FreemiumBannerResponsive: React.FC<FreemiumBannerResponsiveProps> =
  props => {
    const {
      id = 'WIX_ADS',
      className,
      translate,
      useOverlay = false,
      direction,
      href = '',
    } = props;

    const translatedBannerText =
      translate!(
        translations.NAMESPACE,
        translations.MAIN_TEXT_KEY,
        translations.MAIN_TEXT_DEFAULT,
      ) || '';

    const [textBeforeLogo, textAfterLogo] = translatedBannerText.split(
      translations.logoPlaceHolder,
    );

    const translatedLogoText =
      translate!(
        translations.NAMESPACE,
        translations.LOGO_TEXT_KEY,
        translations.LOGO_TEXT_DEFAULT,
      ) || '';

    return (
      <div
        id={id}
        className={classNames(
          className,
          style.freemiumBannerResponsive,
          style[direction],
          useOverlay ? style.overlay : '',
        )}
      >
        {useOverlay ? (
          <div data-testid={TestIds.overlay} className={style.overlay} />
        ) : (
          <Link
            className={style.link}
            href={href}
            target="_blank"
            rel="nofollow"
          >
            <span className={style.text}>
              {textBeforeLogo}
              <EditorXLogo
                className={classNames(style.logo, style[direction])}
                logoText={translatedLogoText}
              />
              {textAfterLogo}
            </span>
          </Link>
        )}
      </div>
    );
  };

export default FreemiumBannerResponsive;
