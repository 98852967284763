import * as React from 'react';
import type { SliderProps as WSRSliderProps } from 'wix-style-react/dist/src/Slider';
import { getDataAttributes } from '@wix/thunderbolt-elements/src/core/commons/utils';
import classNames from 'classnames';
import {
  IRangeSliderProps,
  IRangeSliderImperativeActions,
  SliderType,
} from '../RangeSlider.types';

import { ariaLabels, TestIds, TranslationKeys } from '../constants';
import SteppedSlider from './SteppedSlider/SteppedSlider';
import ContinuousSlider from './ContinuousSlider/ContinuousSlider';
import { st, classes } from './style/RangeSlider.component.st.css';

const SliderToRender: Record<SliderType, React.FC<any>> = {
  Continuous: ContinuousSlider,
  Stepped: SteppedSlider,
};

const toSliderValue = (
  minValue: number,
  maxValue: number,
): WSRSliderProps['value'] => {
  if (minValue !== maxValue) {
    return [minValue, maxValue];
  }
  return minValue;
};

const RangeSlider: React.ForwardRefRenderFunction<
  IRangeSliderImperativeActions,
  IRangeSliderProps
> = (props, ref) => {
  const {
    id,
    className,
    stylableClassName,
    direction,
    label,
    sliderType,
    min,
    max,
    stepType,
    step,
    minValue,
    maxValue,
    prefix,
    suffix,
    marksTickShape,
    isDisabled,
    width,
    readOnly,
    onChange,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    updateComponentPropsInViewer,
    translate,
  } = props;

  const rtl = direction === 'rtl';

  const singleHandle = minValue === maxValue;

  const Slider = SliderToRender[sliderType];

  const stepValue = stepType === 'Number' ? (max - min) / step : step;

  const containerRef = React.useRef<HTMLDivElement>(null);

  const value = React.useMemo(
    () => toSliderValue(minValue, maxValue),
    [minValue, maxValue],
  );

  const handleChange = (newValue: WSRSliderProps['value']) => {
    if (readOnly) {
      return;
    }
    if (typeof newValue === 'number') {
      updateComponentPropsInViewer({ minValue: newValue, maxValue: newValue });
      onChange?.({ type: 'change' } as React.ChangeEvent);
    } else {
      const [newMinValue, newMaxValue] = newValue
        ? newValue
        : [minValue, maxValue];
      updateComponentPropsInViewer({
        minValue: newMinValue,
        maxValue: newMaxValue,
      });
      onChange?.({ type: 'change' } as React.ChangeEvent);
    }
  };

  const translateFn = React.useCallback(
    (key: string, fallbackValue: string) => {
      return translate
        ? translate(ariaLabels.ariaNamespace, key, fallbackValue)
        : () => fallbackValue;
    },
    [translate],
  );

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        containerRef.current?.focus();
      },
      blur: () => {
        containerRef.current?.blur();
      },
    };
  });

  return (
    <div
      id={id}
      className={classNames(className, classes.container)}
      {...getDataAttributes(props)}
    >
      <div
        className={st(
          classes.root,
          { sliderType, marksTickShape, direction, singleHandle },
          stylableClassName,
        )}
        style={
          {
            '--MinThumbPos': (minValue - min) / (max - min),
            '--MaxThumbPos': (maxValue - min) / (max - min),
          } as React.CSSProperties
        }
        ref={containerRef}
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {label && <label className={classes.label}>{label}</label>}
        <Slider
          className={classes.baseSlider}
          dataHook={TestIds.baseSlider}
          rtl={rtl}
          min={min}
          max={max}
          value={value}
          step={stepValue}
          prefix={prefix}
          suffix={suffix}
          onChange={handleChange}
          disabled={isDisabled}
          width={width}
          pushable={true}
          ariaLabelForHandle={[
            translateFn(
              TranslationKeys.minValueAriaLabel,
              ariaLabels.ariaDefaultMinValueLabel,
            ),
            translateFn(
              TranslationKeys.maxValueAriaLabel,
              ariaLabels.ariaDefaultMaxValueLabel,
            ),
          ]}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(RangeSlider);
