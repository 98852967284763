import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { AnchorDefinition } from '@wix/thunderbolt-components';
import { AnchorProps } from '../Anchor.types';

const TOP_AND_BOTTOM_ANCHORS: { [key: string]: string } = {
  SCROLL_TO_TOP: 'top of page',
  SCROLL_TO_BOTTOM: 'bottom of page',
};

export const props = withCompInfo<AnchorProps, AnchorDefinition>()(
  ['compData', 'compId'],
  ({ compData, compId }) => {
    return {
      ...compData,
      name: TOP_AND_BOTTOM_ANCHORS[compId] || compData.name,
    };
  },
);

export default createComponentMapperModel({ props });
