
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TabsList2027980309",
      {"classes":{"root":"TabsList2027980309__root","tabsList":"TabsList2027980309__tabsList","tab":"TabsList2027980309__tab","scrollButton":"TabsList2027980309__scrollButton","label":"TabsList2027980309__label"},"vars":{},"stVars":{"activeIndicatorColor":"color-8","tabsListWrap":"var(--tabs-list-wrap, nowrap)","tabsListGap":"var(--tabs-list-items-gap, 0)","tabsListAlignContent":"var(--tabs-list-content-alignment, flex-start)","tabsListHorizontalPadding":"var(--tabs-list-horizontal-padding, 20px)","tabsListVerticalPadding":"var(--tabs-list-vertical-padding, 20px)","tabsListWrapRowGap":"var(--tabs-list-wrap-row-gap, 0)","tabsListFlexGrow":"var(--tabs-list-item-flex-grow, 0)","tabsListItemBorderWidth":"var(--tabs-list-item-border-width, 0)","tabsFirstChildMarginLeft":"var(--first-child-margin-left, 0)","tabsFirstChildMarginRight":"var(--first-child-margin-right, 0)","tabsLastChildMarginLeft":"var(--last-child-margin-left, 0)","tabsLastChildMarginRight":"var(--last-child-margin-right, 0)","tabsListJustifyContent":"var(--tabs-list-justify-content, normal)","tabsListOverflowX":"var(--tabs-list-overflow-x, visible)","tabsListFlexWrap":"var(--tabs-list-flex-wrap, nowrap)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  