import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  TextInputDefinition,
  TextInputPlatformProps,
  TextInputCssCarmiData,
  TextInputCSSVars,
  TextInputPreviewCSSVars,
} from '../TextInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './TextInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<TextInputPlatformProps>,
  TextInputDefinition
>()(
  viewerPropsMapper,
  {
    add: [
      'shouldResetComponent',
      'componentViewMode',
      'compPreviewState',
      'hasResponsiveLayout',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      shouldResetComponent,
      componentViewMode,
      compPreviewState,
      hasResponsiveLayout,
    } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
        hasResponsiveLayout,
      },
    };
  },
);

const css = composeMapper<
  TextInputPreviewCSSVars | TextInputCSSVars,
  TextInputDefinition,
  TextInputCssCarmiData
>()(viewerCssMapper, {}, (resolver, dependencies, carmiData) => {
  const { compLayout, hasResponsiveLayout } = dependencies;

  const viewerCss = resolver(dependencies, carmiData);

  if (hasResponsiveLayout) {
    return { ...viewerCss };
  }

  return {
    ...viewerCss,
    '--height': `${compLayout.height}px`,
    height: 'var(--height)',
  };
});

export default createComponentPreviewMapperModel({
  props,
  css,
});
