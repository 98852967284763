import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { ISearchBoxControllerActions } from '../SearchBox.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ISearchBoxControllerActions => ({
  closeSuggestions() {
    updateProps({
      isSuggestionsOpen: false,
      closeParentContainer: false,
      suggestionsLoading: false,
    });
  },
  clearSuggestions() {
    updateProps({
      suggestions: null,
    });
  },
  changeValue(value) {
    updateProps({ value });
  },
});

export default mapActionsToProps;
