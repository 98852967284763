import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { MusicPlayerDefinition, MusicPlayerProps } from '../MusicPlayer.types';
import { props as viewerPropsMapper } from './MusicPlayer.mapper';

const props = composeMapper<
  PreviewWrapperProps<MusicPlayerProps>,
  MusicPlayerDefinition
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { isPlayingAllowed } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: { isPlayingAllowed },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
