import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { Layout } from '../documentManagement/VKShareButton.schemaTypes';
import {
  VKShareButtonDefinition,
  VKShareButtonMapperProps,
  VKShareButtonCSSVars,
  VKShareButtonData,
  VKShareButtonStateRefs,
} from '../VKShareButton.types';

type Layout_Dimensions = {
  width: number;
  height: number;
};
export const LAYOUT_SIZES: Record<Layout, Layout_Dimensions> = {
  Button: { width: 100, height: 21 },
  ButtonWithoutCounter: { width: 64, height: 21 },
  Link: { width: 50, height: 21 },
  LinkWithoutIcon: { width: 30, height: 21 },
  Icon: { width: 32, height: 32 },
};

const getIframeParams = (compData: VKShareButtonData) => ({
  layout: compData.style,
  text: compData.text || 'Share',
});

const getLayout = (value: Layout) => LAYOUT_SIZES[value] || LAYOUT_SIZES.Button;

export const props = withCompInfo<
  VKShareButtonMapperProps,
  VKShareButtonDefinition
>()(['compData'], ({ compData }) => {
  const iframeParams = getIframeParams(compData);

  return {
    iframeParams,
  };
});

export const css = withCompInfo<
  VKShareButtonCSSVars,
  VKShareButtonDefinition
>()(['compData'], ({ compData }) => {
  const { style } = compData;
  const layout = getLayout(style);

  return {
    '--width': `${layout.width}px`,
    height: `${layout.height}px`,
  };
});

export const stateRefs = withStateRefsValues<VKShareButtonStateRefs>([
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({ props, css, stateRefs });
