import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetDefinition,
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
} from '../FlickrBadgeWidget.types';

const props = withCompInfo<
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeWidgetDefinition
>()(['compData'], ({ compData }) => {
  return {
    userId: compData.userId,
    tag: compData.tag,
    imageCount: `${compData.imageCount}`,
    whichImages: compData.whichImages,
    imageSize: compData.imageSize,
    layoutOrientation: compData.layoutOrientation,
  };
});

export const stateRefs = withStateRefsValues<FlickrBadgeStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ props, stateRefs });
